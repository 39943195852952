import { useState } from "react";
import { useEffectOnce } from "react-use";
import { UserBookedTickets, getUserBookedTickets } from "./api/EventApi";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import { GrGroup } from "react-icons/gr";

export function PastEvents() {
  const [tickets, setTickets] = useState<UserBookedTickets>({ booked: [] });

  useEffectOnce(() => {
    getUserBookedTickets().then(result => {
      setTickets(result);
    });
  });

  return (
    <div className="container">
      <div className="title">Afgelopen events</div>

      {tickets &&
      tickets.booked &&
      tickets.booked.filter(
        e => DateTime.fromISO(e.eventStartDate).toUTC() < DateTime.utc()
      ).length === 0 ? (
        "Momenteel nog geen tickets beschikbaar"
      ) : (
        <div>
          <table className="table ">
            <thead>
              <tr>
                <th>Event</th>
                <th>Ticket</th>
                <th>Datum</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {tickets &&
                tickets.booked &&
                tickets.booked
                  .filter(
                    e =>
                      DateTime.fromISO(e.eventStartDate).toUTC() <
                      DateTime.utc()
                  )
                  .map((booking, index) => {
                    const start = DateTime.fromISO(booking.eventStartDate);
                    const end = DateTime.fromISO(booking.eventEndDate);

                    return (
                      <tr key={index}>
                        <td>{booking.eventName}</td>
                        <td>{booking.ticketName}</td>
                        <td>
                          {start.equals(end)
                            ? start.toFormat("dd/LL/y HH'.'mm 'uur'")
                            : start.day === end.day &&
                              start.month === end.month &&
                              start.year === end.year
                            ? `${start.toFormat(
                                "dd/LL/y HH'.'mm 'uur'"
                              )} - ${end.toFormat("HH'.'mm 'uur'")}`
                            : `${start.toFormat(
                                "dd/LL/y HH'.'mm 'uur'"
                              )} - ${end.toFormat("dd/LL/y HH'.'mm 'uur'")}`}
                        </td>
                        <td>
                          <Link
                            to={`/user/visitors/${booking.eventId}`}
                            className="visitors"
                          >
                            <GrGroup />
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

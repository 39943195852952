import Logo from "./img/logo.png";
import { AiOutlineUser } from "react-icons/ai";
import "./Header.css";
import { Link, NavLink } from "react-router-dom";
import { isAdmin } from "./api/AuthApi";

export function Header({ authenticated }: { authenticated: boolean }) {
  return (
    <div className="header items-center">
      <div>
        <Link to="/">
          <img className="logo" src={Logo} alt={"Gentrepreneur"} />
        </Link>
      </div>
      <div className="ml-auto user">
        <div className="flex items-end">
          {authenticated && isAdmin() ? (
            <>
              <NavLink to="/admin/events" className="link">
                Events
              </NavLink>
              <NavLink to="/admin/users" className="link">
                Gebruikers
              </NavLink>
              <NavLink to="/admin/general" className="link">
                Algemeen
              </NavLink>
              <div
                className="link"
                onClick={() => {
                  window.location.href = "/login";
                  localStorage.clear();
                }}
              >
                Uitloggen
              </div>
            </>
          ) : null}
          {authenticated && !isAdmin() ? (
            <>
              <NavLink to="/user/events" className="link">
                Aankomende events
              </NavLink>
              <NavLink to="/user/past" className="link">
                Afgelopen events
              </NavLink>
              <NavLink to="/user/profile" className="link">
                Mijn profiel
              </NavLink>
              <div
                className="link"
                onClick={() => {
                  window.location.href = "/login";
                  localStorage.clear();
                }}
              >
                Uitloggen
              </div>
            </>
          ) : null}
          <Link to="/login">
            <AiOutlineUser />
          </Link>
        </div>
      </div>
    </div>
  );
}

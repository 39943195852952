import { useState } from "react";
import { resetPassword } from "./api/AuthApi";
import { useParams } from "react-router-dom";

export function Reset() {
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const { id } = useParams<{ id: string }>();
  const [message, setMessage] = useState("");

  return (
    <div className="account container flex flex-column justify-center">
      <div className="title">Wachtwoord aanpassen</div>
      <form
        onSubmit={async e => {
          e.preventDefault();

          if (password !== password2) {
            setMessage("Wachtwoorden komen niet overeen!");
            return;
          }

          await resetPassword(password, id || "");

          setPassword("");
          setPassword2("");
          setMessage("Wachtwoord is aangepast.");
        }}
      >
        <div className="flex items-center flex-column">
          <input
            className="input"
            type="password"
            placeholder="Wachtwoord"
            value={password}
            onChange={e => {
              setPassword(e.target.value);
            }}
            required
          />
          <input
            className="input"
            type="password"
            placeholder="Herhaal wachtwoord"
            value={password2}
            onChange={e => {
              setPassword2(e.target.value);
            }}
            required
          />
          {message ? <div className="mt-4 mb-4">{message}</div> : null}
          <button type="submit" className="button">
            Wachtwoord aanpassen
          </button>
        </div>
      </form>
    </div>
  );
}

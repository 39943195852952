import "./Home.css";
import { useState } from "react";
import { useEffectOnce } from "react-use";
import { listEvents, Event } from "./api/EventApi";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";

export function Home() {
  const [events, setEvents] = useState<Event[]>([]);

  useEffectOnce(() => {
    listEvents().then(r => {
      setEvents(r);
    });
  });

  return (
    <div className="home container">
      <div className="title">
        Workshops en events voor ondernemende jongeren
      </div>
      <div className="subtitle">
        om je ondernemende skills, of info bij te spijkeren, te netwerken of
        inspiratie op te doen.
      </div>
      <div className="event-wrapper">
        {events.map(event => {
          const start = DateTime.fromISO(event.start_date);
          const end = DateTime.fromISO(event.end_date);

          return (
            <Link to={`/events/${event.id}`} className="flex event">
              <div>
                <img
                  className="image"
                  alt={event.name}
                  src={event.image_square}
                />
              </div>
              <div>
                <b>{event.name}</b>
              </div>
              <div className="mb-4">
                {start.equals(end)
                  ? DateTime.fromISO(event.start_date).toFormat(
                      "dd/LL/y HH'.'mm 'uur'"
                    )
                  : start.day === end.day &&
                    start.month === end.month &&
                    start.year === end.year
                  ? `${DateTime.fromISO(event.start_date).toFormat(
                      "dd/LL/y HH'.'mm 'uur'"
                    )} - ${DateTime.fromISO(event.end_date).toFormat(
                      "HH'.'mm 'uur'"
                    )}`
                  : `${DateTime.fromISO(event.start_date).toFormat(
                      "dd/LL/y HH'.'mm 'uur'"
                    )} - ${DateTime.fromISO(event.end_date).toFormat(
                      "dd/LL/y HH'.'mm 'uur'"
                    )}`}
              </div>
              <div>{event.small_text}</div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}

import { API_ENDPOINT, getHeaders } from "./AuthApi";

export type Ticket = {
  id: string;
  name: string;
  description: string;
  max_tickets: number;
  event_id: string;
  slot: string | null;
  hubspotProperty: string;
};

export type Visitor = {
  firstName: string;
  lastName: string;
  linkedIn: string;
};

export async function deleteTicket(
  eventId: string,
  ticketId: string
): Promise<void> {
  await fetch(`${API_ENDPOINT}/events/${eventId}/tickets/${ticketId}`, {
    method: "DELETE",
    headers: getHeaders()
  }).then(r => r.json());
}

export async function deleteAllTickets(eventId: string): Promise<void> {
  await fetch(`${API_ENDPOINT}/events/${eventId}/tickets/all`, {
    method: "DELETE",
    headers: getHeaders()
  }).then(r => r.json());
}

export async function getTicketStatus(
  eventId: string
): Promise<{ ticketId: string; available: boolean }[]> {
  const result = await fetch(
    `${API_ENDPOINT}/events/${eventId}/tickets/status`,
    {
      method: "GET",
      headers: getHeaders()
    }
  ).then(r => r.json());

  if (!result.statuses) {
    return [];
  }

  return result.statuses;
}

export async function updateTicket(
  event_id: string,
  id: string,
  name: string,
  description: string,
  max_tickets: number,
  slot: string | null,
  hubspotProperty: string
): Promise<Ticket> {
  const result = await fetch(
    `${API_ENDPOINT}/events/${event_id}/tickets/${id}`,
    {
      method: "PUT",
      headers: getHeaders(),
      body: JSON.stringify({
        event_id: event_id,
        id: id,
        name: name,
        description: description,
        max_tickets: max_tickets,
        slot: slot,
        hubspotProperty: hubspotProperty
      })
    }
  ).then(r => r.json());

  return result.ticket;
}

export async function createTicket(
  event_id: string,
  name: string,
  description: string,
  max_tickets: number,
  slot: string | null,
  id: string,
  hubspotProperty: string
): Promise<Ticket> {
  const result = await fetch(`${API_ENDPOINT}/events/${event_id}/tickets`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      event_id: event_id,
      name: name,
      description: description,
      max_tickets: max_tickets,
      slot: slot,
      id: id,
      hubspotProperty: hubspotProperty
    })
  }).then(r => r.json());

  return result.ticket;
}

export async function reserveTicket(
  ticket_id: string,
  event_id: string,
  amount: number,
  id: string
): Promise<{ ok: boolean; left: number }> {
  const result = await fetch(`${API_ENDPOINT}/reserve`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      ticket_id: ticket_id,
      event_id: event_id,
      amount: amount,
      id: id
    })
  }).then(r => r.json());

  return result;
}

export async function unreserveTicket(
  id: string
): Promise<{ ok: boolean; left: number }> {
  const result = await fetch(`${API_ENDPOINT}/unreserve`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      id: id
    })
  }).then(r => r.json());

  return result;
}

export async function bookTicket(
  data: {
    firstName: string;
    lastName: string;
    email: string;
    questions: any;
    ticket_id: string;
    event_id: string;
    id: string;
    showVisit: boolean;
    linkedIn: string;
  }[]
): Promise<{ ok: boolean; left: number }> {
  const result = await fetch(`${API_ENDPOINT}/book`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      data: data
    })
  }).then(r => r.json());

  return result;
}

export async function listTickets(eventId: string): Promise<Ticket[]> {
  const result = await fetch(`${API_ENDPOINT}/events/${eventId}/tickets`, {
    method: "GET",
    headers: getHeaders()
  }).then(r => r.json());

  return result.tickets;
}

export async function getVisitors(eventId: string): Promise<Visitor[]> {
  const result = await fetch(`${API_ENDPOINT}/visitors/${eventId}`, {
    method: "GET",
    headers: getHeaders()
  }).then(r => r.json());

  return result.visitors;
}

import React, { PropsWithChildren } from "react";
import ReactModal from "react-modal";
import "./Dialog.css";
import { AiOutlineClose } from "react-icons/ai";

export default function Dialog({
  isOpen,
  children,
  onRequestClose,
  title,
  size,
  padding,
  shouldCloseOnOverlayClick = true,
  shouldCloseOnEsc = true,
  overflow = true,
}: PropsWithChildren<{
  isOpen: boolean;
  onRequestClose: () => void;
  shouldCloseOnOverlayClick?: boolean;
  shouldCloseOnEsc?: boolean;
  title: string;
  size: "small" | "wide" | "xsmall" | "large" | "slide" | "full";
  padding: boolean;
  overflow?: boolean;
}>) {
  const classNames = ["dialog-body"];
  if (size) {
    classNames.push(`is-${size}`);
  }
  if (padding) {
    classNames.push("dialog-padding");
  }
  if (!overflow) {
    classNames.push("overflow-inherit");
  }

  return (
    <ReactModal
      isOpen={isOpen}
      appElement={window.document.getElementById("root") as HTMLElement}
      onRequestClose={onRequestClose}
      className={classNames.join(" ")}
      overlayClassName="dialog-overlay"
      bodyOpenClassName="dialog-open"
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc={shouldCloseOnEsc}
    >
      {!title ? null : (
        <div
          className={"dialog-header items-center " + (padding ? "mb-4" : "p-8")}
        >
          <div className="flex items-center">
            <div className="dialog-title">{title}</div>
          </div>
          <div
            className="close-button ml-auto"
            title="Close dialog"
            onClick={() => {
              onRequestClose();
            }}
          >
            <AiOutlineClose />
          </div>
        </div>
      )}
      {isOpen ? children : null}
    </ReactModal>
  );
}

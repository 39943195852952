import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useEffectOnce } from "react-use";
import { getEvent, Event } from "./api/EventApi";
import "./Event.css";
import {
  bookTicket,
  getTicketStatus,
  listTickets,
  reserveTicket,
  Ticket,
  unreserveTicket,
} from "./api/TicketApi";
import { Checkbox } from "./Checkbox";
import { getDecodedToken, register } from "./api/AuthApi";
import ReactMarkdown from "react-markdown";
import {
  createResponse,
  getCurrentResponses,
  getQuestions,
  Question,
} from "./api/QuestionsApi";
import { LoadQuestions } from "./LoadQuestions";
import { EventType } from "./EventForm";
import { DateTime } from "luxon";
import { ulid } from "ulid";

export function EventView({
  setAuthenticated,
}: {
  setAuthenticated: (b: boolean) => void;
}) {
  const { eventId } = useParams<{ eventId: string }>();
  const [event, setEvent] = useState<Event | null>(null);
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [selectedTicket, setSelectedTicket] = useState("");
  const [selectedTickets, setSelectedTickets] = useState<
    {
      id: string;
      name: string;
      slot: string | null;
    }[]
  >([]);
  const [error, setError] = useState("");
  const [isReserved, setIsReserved] = useState<null | string>(null);
  const [email, setEmail] = useState(getDecodedToken().email);
  const [firstName, setFirstName] = useState(getDecodedToken().firstName);
  const [lastName, setLastName] = useState(getDecodedToken().lastName);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [responses, setResponses] = useState<
    { question: string; response: string }[]
  >([]);
  const [generalQuestions, setGeneralQuestions] = useState<Question[]>([]);
  const [generalResponses, setGeneralResponses] = useState<
    { question: string; response: string }[]
  >([]);
  const mappedTicket = tickets.find((t) => t.id === selectedTicket);
  const [bookedMessage, setBookedMessage] = useState<null | string>(null);
  const [chooseTickets, setChooseTickets] = useState(false);
  const [loading, setLoading] = useState(true);
  const [ticketStatuses, setTicketStatuses] = useState<
    { ticketId: string; available: boolean }[]
  >([]);
  const [password, setPassword] = useState("");
  const [reviewedData, setReviewedData] = useState(false);
  const [showVisit, setShowVisit] = useState(false);
  const [linkedIn, setLinkedIn] = useState("");
  const [loadingRequest, setLoadingRequest] = useState(false);

  useEffectOnce(() => {
    getEvent(eventId || "").then((r) => {
      setEvent(r);
      setLoading(false);
    });

    listTickets(eventId || "").then((r) => {
      setTickets(r);
    });

    getTicketStatus(eventId || "").then((r) => {
      setTicketStatuses(r);
    });

    getQuestions(eventId || "").then((r) => {
      setQuestions(r.reverse());
      setResponses(
        r.reverse().map((q) => {
          return {
            question: q.question,
            response: "",
          };
        })
      );
    });

    getQuestions("general").then((quests) => {
      setGeneralQuestions(quests.reverse());

      getCurrentResponses().then((r) => {
        setGeneralResponses(
          r.responses
            ? r.responses
            : quests.reverse().map((q) => {
                return {
                  question: q.question,
                  response: "",
                };
              })
        );
      });
    });
  });

  useEffect(() => {
    return () => {
      const savedReserved = localStorage.getItem("reserved");

      if (!savedReserved) {
        return;
      }

      unreserveTicket(savedReserved).then(() => {});

      localStorage.removeItem("reserved");
    };
  }, []);

  const uniqueTickets = Array.from(new Set(tickets.map((t) => t.name)));
  const uniqueSlots = Array.from(
    new Set(tickets.filter((t) => t.slot).map((t) => t.slot))
  ) as string[];

  if (loading) {
    return null;
  }

  if (!event) {
    return (
      <div>
        <div className="title">Event verlopen!</div>
        <div className="subtitle">
          Het event is helaas al verlopen, houd onze socials in de gaten voor de
          volgende editie!
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={`container event ${event.color}`}>
        <div>
          <img className="banner" alt={event.name} src={event.image_banner} />
        </div>
        <div className="flex justify-between">
          {isReserved ? (
            <div className="flex flex-column items-center ml-auto mr-auto">
              {bookedMessage ? (
                <div className="title">Je ticket is bevestigd</div>
              ) : (
                <div className="title">Bevestig uw gegevens</div>
              )}

              {bookedMessage ? (
                <div dangerouslySetInnerHTML={{ __html: bookedMessage }} />
              ) : (
                <>
                  <div className="mb-4">
                    <b className="no-bg">Ticket:</b> {mappedTicket?.name}
                  </div>
                  <label>E-mail</label>
                  <input
                    className="input"
                    type="email"
                    placeholder="E-mail"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    required
                  />
                  <label>Voornaam</label>
                  <input
                    className="input"
                    type="text"
                    placeholder="Voornaam"
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                    required
                  />
                  <label>Achternaam</label>
                  <input
                    className="input"
                    type="text"
                    placeholder="Achternaam"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                    required
                  />
                  {getDecodedToken().userId === "" ? (
                    <>
                      <label>
                        Met een Gentrepreneur-Account kun je onder meer het
                        volgende doen:
                      </label>
                      <ul>
                        <li>
                          In een klik inschrijven voor volgende evenementen
                        </li>
                        <li>
                          Overzicht van voorafgaande én opkomende evenementen
                        </li>
                        <li>Makkelijk uitschrijven</li>
                        <li>
                          Snel informatie vinden over het evenement waar je voor
                          bent ingeschreven
                        </li>
                      </ul>
                      <label>
                        Vul een wachtwoord in om je account aan te maken:
                      </label>
                      <input
                        className="input"
                        type="password"
                        placeholder="Wachtwoord"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                      />
                    </>
                  ) : null}
                  {event.disableQuestions ? null : (
                    <LoadQuestions
                      questions={generalQuestions}
                      responses={generalResponses}
                      setResponses={setGeneralResponses}
                    />
                  )}
                  <LoadQuestions
                    questions={questions}
                    responses={responses}
                    setResponses={setResponses}
                  />
                  <Checkbox
                    title={
                      "Andere deelnemers van de events die ik bezoek mogen zien dat ik ook aanwezig was/ben/zal zijn"
                    }
                    value={showVisit}
                    onClick={setShowVisit}
                  />
                  {showVisit ? (
                    <>
                      <label>LinkedIn URL</label>
                      <input
                        className="input"
                        type="text"
                        placeholder="LinkedIn URL"
                        value={linkedIn}
                        onChange={(e) => {
                          setLinkedIn(e.target.value);
                        }}
                      />
                    </>
                  ) : null}
                  <Checkbox
                    title={
                      "Ik keek mijn gegevens na en bevestig dat dit klopt."
                    }
                    value={reviewedData}
                    onClick={setReviewedData}
                  />
                  {error ? <div className="error">{error}</div> : null}
                  <button
                    disabled={loadingRequest}
                    className="button pointer mt-4"
                    onClick={async () => {
                      if (!email || !firstName || !lastName) {
                        setError("Gelieve je email en naam in te vullen");
                        return;
                      }

                      if (
                        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(
                          email
                        )
                      ) {
                        setError("Gelieve je email te controleren!");
                        return;
                      }

                      if (!reviewedData) {
                        setError(
                          "Gelieve je gegevens te controleren en dit te bevestigen!"
                        );
                        return;
                      }

                      for (const question of questions) {
                        const foundResponse = responses.find(
                          (r) => r.question === question.question
                        );

                        const mainQuestion = questions.find(
                          (q) =>
                            question.dependsOn !== null &&
                            q.question === question.dependsOn
                        );
                        const mainResponse = responses.find(
                          (r) => r.question === mainQuestion?.question
                        );

                        if (
                          question.required &&
                          foundResponse &&
                          foundResponse.response.trim() === ""
                        ) {
                          if (
                            mainQuestion &&
                            question.dependsOn !== null &&
                            mainResponse?.response !== question.dependsValue
                          ) {
                            continue;
                          }

                          setError(
                            `Gelieve de vraag '${question.question}' in te vullen`
                          );
                          return;
                        }
                      }

                      if (!event.disableQuestions) {
                        for (const question of generalQuestions) {
                          const foundResponse = generalResponses.find(
                            (r) => r.question === question.question
                          );

                          const mainQuestion = generalQuestions.find(
                            (q) =>
                              question.dependsOn !== null &&
                              q.question === question.dependsOn
                          );
                          const mainResponse = generalResponses.find(
                            (r) => r.question === mainQuestion?.question
                          );

                          if (
                            question.required &&
                            foundResponse &&
                            foundResponse.response.trim() === ""
                          ) {
                            if (
                              mainQuestion &&
                              question.dependsOn !== null &&
                              mainResponse?.response !== question.dependsValue
                            ) {
                              continue;
                            }

                            setError(
                              `Gelieve de vraag '${question.question}' in te vullen`
                            );
                            return;
                          }
                        }
                      }

                      try {
                        setLoadingRequest(true);
                        let reservedTickets: string[] = [];

                        if (selectedTicket) {
                          reservedTickets = [selectedTicket];
                        }

                        if (selectedTickets.length > 0) {
                          reservedTickets = selectedTickets.map((t) => t.id);
                        }

                        await bookTicket(
                          reservedTickets.map((t) => {
                            return {
                              firstName: firstName,
                              lastName: lastName,
                              email: email,
                              ticket_id: t,
                              event_id: eventId || "",
                              id: isReserved,
                              questions: generalResponses.concat(responses),
                              showVisit: showVisit,
                              linkedIn: linkedIn,
                            };
                          })
                        );

                        setBookedMessage(
                          "Check je inbox (of spam) voor een bevestigingsmail. Geen mailtje ontvangen? Stuur even naar <a href='mailto:info@gentrepreneur.gent'>info@gentrepreneur.gent</a>"
                        );
                        setLoadingRequest(false);
                      } catch (e) {
                        setBookedMessage(e as string);
                        setLoadingRequest(false);
                      }

                      if (password.trim() !== "") {
                        const result = await register(
                          firstName,
                          lastName,
                          email,
                          password
                        );

                        await createResponse({ responses: generalResponses });

                        if (result.ok) {
                          setAuthenticated(true);
                        }
                      }
                    }}
                  >
                    Bevestigen
                  </button>
                </>
              )}
            </div>
          ) : (
            <>
              <div
                className={`${
                  event.eventType === EventType.EXPERTS ||
                  event.eventType === EventType.DAY ||
                  event.eventType === EventType.SPECIAL
                    ? "w-100 overflow-auto"
                    : "w-45"
                }`}
              >
                {!chooseTickets ? (
                  <>
                    <div className="title small">
                      <span>Beschrijving</span>
                    </div>
                    <ReactMarkdown>{event.description}</ReactMarkdown>
                  </>
                ) : (
                  <>
                    <div className="title small">
                      <span>
                        {event.eventType === EventType.DAY
                          ? "Workshops"
                          : "Momenten"}
                      </span>
                    </div>
                    <div
                      className={`flex ${
                        event.eventType === EventType.DAY ? "flex-column" : ""
                      }`}
                    >
                      {event.eventType === EventType.EXPERTS &&
                        uniqueTickets.map((unique) => {
                          const allTickets = tickets
                            .filter((t) => unique === t.name)
                            .sort((a, b) =>
                              a.slot && b.slot
                                ? DateTime.fromISO(a.slot).toMillis() -
                                  DateTime.fromISO(b.slot).toMillis()
                                : 0
                            );

                          return (
                            <div className="ml-4">
                              <div className="mb-4">
                                <b>{unique}</b>
                              </div>
                              <div className="mb-4">
                                {allTickets.length > 0
                                  ? allTickets[0].description
                                  : null}
                              </div>
                              {allTickets.map((ticket) => {
                                return (
                                  <div className="mb-4">
                                    <Checkbox
                                      disabled={
                                        event.eventType === EventType.EXPERTS &&
                                        ticketStatuses.find(
                                          (t) => t.ticketId === ticket.id
                                        )?.available === false
                                      }
                                      title={
                                        ticket.slot
                                          ? DateTime.fromISO(
                                              ticket.slot
                                            ).toFormat("HH'.'mm 'uur'")
                                          : "Nog te bepalen"
                                      }
                                      value={selectedTickets
                                        .map((t) => t.id)
                                        .includes(ticket.id)}
                                      onClick={(checked) => {
                                        if (checked) {
                                          setSelectedTickets(
                                            selectedTickets
                                              .filter(
                                                (t) =>
                                                  t.name !== unique &&
                                                  t.slot !== ticket.slot
                                              )
                                              .concat({
                                                id: ticket.id,
                                                name: unique,
                                                slot: ticket.slot,
                                              })
                                          );
                                        } else {
                                          setSelectedTickets(
                                            selectedTickets.filter(
                                              (t) => t.id !== ticket.id
                                            )
                                          );
                                        }
                                      }}
                                    />
                                    {event.eventType === EventType.DAY &&
                                    ticketStatuses.find(
                                      (t) => t.ticketId === ticket.id
                                    )?.available === false ? (
                                      <div>
                                        <b>Je komt op de wachtlijst terecht</b>
                                      </div>
                                    ) : null}
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                      {event.eventType === EventType.SPECIAL &&
                        uniqueTickets.map((unique) => {
                          const allTickets = tickets
                            .filter((t) => unique === t.name)
                            .sort((a, b) =>
                              a.slot && b.slot
                                ? DateTime.fromISO(a.slot).toMillis() -
                                  DateTime.fromISO(b.slot).toMillis()
                                : 0
                            );

                          return (
                            <div className="ml-4">
                              <div className="mb-4">
                                <b>{unique}</b>
                              </div>
                              {allTickets.map((ticket) => {
                                return (
                                  <div className="mb-4">
                                    <Checkbox
                                      disabled={
                                        event.eventType === EventType.EXPERTS &&
                                        ticketStatuses.find(
                                          (t) => t.ticketId === ticket.id
                                        )?.available === false
                                      }
                                      title={`${
                                        ticket.slot
                                          ? DateTime.fromISO(
                                              ticket.slot
                                            ).toFormat("HH'.'mm 'uur'")
                                          : "Nog te bepalen"
                                      } - ${ticket.description}`}
                                      value={selectedTickets
                                        .map((t) => t.id)
                                        .includes(ticket.id)}
                                      onClick={(checked) => {
                                        if (checked) {
                                          setSelectedTickets(
                                            selectedTickets
                                              .filter(
                                                (t) =>
                                                  t.name !== unique &&
                                                  t.slot !== ticket.slot
                                              )
                                              .concat({
                                                id: ticket.id,
                                                name: unique,
                                                slot: ticket.slot,
                                              })
                                          );
                                        } else {
                                          setSelectedTickets(
                                            selectedTickets.filter(
                                              (t) => t.id !== ticket.id
                                            )
                                          );
                                        }
                                      }}
                                    />
                                    {event.eventType === EventType.SPECIAL &&
                                    ticketStatuses.find(
                                      (t) => t.ticketId === ticket.id
                                    )?.available === false ? (
                                      <div>
                                        <b>Je komt op de wachtlijst terecht</b>
                                      </div>
                                    ) : null}
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                      {event.eventType === EventType.DAY &&
                        uniqueSlots
                          .sort((a, b) => {
                            return (
                              DateTime.fromISO(a).toMillis() -
                              DateTime.fromISO(b).toMillis()
                            );
                          })
                          .map((unique) => {
                            const allTickets = tickets.filter(
                              (t) => unique === t.slot
                            );

                            return (
                              <div className="ml-4">
                                <div className="mb-4">
                                  <b>
                                    {DateTime.fromISO(unique).toFormat(
                                      "HH'.'mm 'uur'"
                                    )}
                                  </b>
                                </div>
                                <div className="mb-4">
                                  {allTickets.length > 0
                                    ? allTickets[0].description
                                    : null}
                                </div>
                                <div className="flex">
                                  {allTickets.map((ticket) => {
                                    return (
                                      <div className="mb-4 mr-4">
                                        <Checkbox
                                          disabled={
                                            event.eventType ===
                                              EventType.EXPERTS &&
                                            ticketStatuses.find(
                                              (t) => t.ticketId === ticket.id
                                            )?.available === false
                                          }
                                          title={ticket.name}
                                          value={selectedTickets
                                            .map((t) => t.id)
                                            .includes(ticket.id)}
                                          onClick={(checked) => {
                                            if (checked) {
                                              setSelectedTickets(
                                                selectedTickets
                                                  .filter(
                                                    (t) =>
                                                      t.name !== unique &&
                                                      t.slot !== ticket.slot
                                                  )
                                                  .concat({
                                                    id: ticket.id,
                                                    name: unique,
                                                    slot: ticket.slot,
                                                  })
                                              );
                                            } else {
                                              setSelectedTickets(
                                                selectedTickets.filter(
                                                  (t) => t.id !== ticket.id
                                                )
                                              );
                                            }
                                          }}
                                        />
                                        {event.eventType === EventType.DAY &&
                                        ticketStatuses.find(
                                          (t) => t.ticketId === ticket.id
                                        )?.available === false ? (
                                          <div>
                                            <b>
                                              Je komt op de wachtlijst terecht
                                            </b>
                                          </div>
                                        ) : null}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          })}
                    </div>
                    {error !== "" ? (
                      <div className="mt-4 error">{error}</div>
                    ) : null}
                    {DateTime.utc() >=
                    DateTime.fromISO(event.end_order_date).toUTC() ? (
                      <div className="error">
                        Helaas kun je geen tickets meer bestellen.
                      </div>
                    ) : (
                      <div
                        className="button pointer mt-4"
                        onClick={async () => {
                          if (selectedTickets.length === 0) {
                            setError("Gelieve een ticket te kiezen");
                            return;
                          }

                          const id = ulid();

                          let result;
                          let ticketName = "";

                          for (const ticket of selectedTickets) {
                            result = await reserveTicket(
                              ticket.id,
                              eventId || "",
                              1,
                              id
                            );

                            if (!result.ok) {
                              ticketName = ticket.name;
                              break;
                            }
                          }

                          if (result && !result.ok) {
                            if (result.left === 0) {
                              setError(
                                `Er zijn geen tickets meer beschikbaar voor ${ticketName}, probeer later opnieuw!`
                              );
                            } else {
                              setError(
                                `Er zijn maar ${result.left} ticket(s) meer beschikbaar voor ${ticketName}!`
                              );
                            }

                            return;
                          }

                          setIsReserved(id);
                          localStorage.setItem("reserved", id);
                        }}
                      >
                        Bestel nu
                      </div>
                    )}
                  </>
                )}

                {(event.eventType === EventType.EXPERTS ||
                  event.eventType === EventType.DAY ||
                  event.eventType === EventType.SPECIAL) &&
                !chooseTickets ? (
                  DateTime.utc() >=
                  DateTime.fromISO(event.end_order_date).toUTC() ? (
                    <div className="error">
                      Helaas kun je geen tickets meer bestellen.
                    </div>
                  ) : (
                    <div
                      className="button pointer mt-4"
                      onClick={() => {
                        setChooseTickets(true);
                      }}
                    >
                      {event.eventType === EventType.DAY
                        ? "Workshops"
                        : "Momenten"}{" "}
                      kiezen
                    </div>
                  )
                ) : null}
              </div>
              {event.eventType !== EventType.EXPERTS &&
              event.eventType !== EventType.DAY &&
              event.eventType !== EventType.SPECIAL ? (
                <div className="w-45">
                  <div className="title small">
                    <span>Tickets</span>
                  </div>
                  {tickets.map((ticket) => {
                    return (
                      <div className="mb-4">
                        <Checkbox
                          title={ticket.name}
                          value={selectedTicket === ticket.id}
                          onClick={(checked) => {
                            if (checked) {
                              setSelectedTicket(ticket.id);
                            }
                          }}
                        />
                        <div>{ticket.description}</div>
                        {ticketStatuses.find((t) => t.ticketId === ticket.id)
                          ?.available === false ? (
                          <div>
                            <b>Je komt op de wachtlijst terecht</b>
                          </div>
                        ) : null}
                      </div>
                    );
                  })}
                  <div className="flex items-center flex-column">
                    {error !== "" ? (
                      <div className="mt-4 error">{error}</div>
                    ) : null}
                    {DateTime.utc() >=
                    DateTime.fromISO(event.end_order_date).toUTC() ? (
                      <div className="error">
                        Helaas kun je geen tickets meer bestellen.
                      </div>
                    ) : (
                      <div
                        className="button pointer mt-4"
                        onClick={async () => {
                          if (selectedTicket === "") {
                            setError("Gelieve een ticket te kiezen");
                            return;
                          }

                          const id = ulid();

                          const result = await reserveTicket(
                            selectedTicket,
                            eventId || "",
                            1,
                            id
                          );

                          if (!result.ok) {
                            if (result.left === 0) {
                              setError(
                                "Er zijn geen tickets meer beschikbaar, probeer later opnieuw!"
                              );
                            } else {
                              setError(
                                `Er zijn maar ${result.left} tickets meer beschikbaar!`
                              );
                            }

                            return;
                          }

                          setIsReserved(id);
                          localStorage.setItem("reserved", id);
                        }}
                      >
                        Bestel nu
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
            </>
          )}
        </div>
      </div>
      <div className={`event ${event.color} cta`}>
        <ReactMarkdown>{event.tagline}</ReactMarkdown>
      </div>
    </>
  );
}

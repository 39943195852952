import React, { useRef } from "react";
import "./ContentEditor.css";
import { EmailEditor } from "react-email-editor";

export function ContentEditor({
  design,
  save,
  cancel
}: {
  design: any;
  save: (data: { html: string; design: string }) => void;
  cancel: () => void;
}) {
  const emailEditorRef = useRef<any>(null);

  return (
    <div className="editor-wrapper">
      <EmailEditor
        projectId={178422}
        ref={emailEditorRef}
        onLoad={() => {
          emailEditorRef?.current?.editor.loadDesign(JSON.parse(design));
        }}
        displayMode="email"
      />
      <div className="container mt-4 flex">
        <div
          className="button"
          onClick={() => {
            emailEditorRef.current.editor.exportHtml((data: any) => {
              const { design, html } = data;

              console.log(html);
              console.log("======");
              console.log(html.replaceAll("\n", ""));

              save({
                design: JSON.stringify(design),
                html: html.replaceAll("\n", "")
              });
            });
          }}
        >
          Save
        </div>
        <div className="button ml-4" onClick={cancel}>
          Cancel
        </div>
      </div>
    </div>
  );
}

import { API_ENDPOINT, getHeaders } from "./AuthApi";

export type Question = {
  id: string;
  question: string;
  type: string;
  options: { value: string; label: string }[];
  event: string;
  required: boolean;
  dependsOn: string | null;
  dependsValue: string;
  position: number;
  hubspotProperty: string;
};

export type AllResponse = {
  userId: string;
  responses: { question: string; response: string }[];
};

export type Response = {
  responses: { question: string; response: string }[];
};

export async function getQuestions(eventId: string): Promise<Question[]> {
  const result = await fetch(`${API_ENDPOINT}/questions/${eventId}`, {
    method: "GET",
    headers: getHeaders()
  }).then(r => r.json());

  return result.questions;
}

export async function getAllResponses(): Promise<AllResponse[]> {
  const result = await fetch(`${API_ENDPOINT}/responses`, {
    method: "GET",
    headers: getHeaders()
  }).then(r => r.json());

  return result.responses;
}

export async function getCurrentResponses(): Promise<Response> {
  const result = await fetch(`${API_ENDPOINT}/responses/current`, {
    method: "GET",
    headers: getHeaders()
  }).then(r => r.json());

  return result;
}

export async function createResponse(responses: Response): Promise<Response> {
  const result = await fetch(`${API_ENDPOINT}/responses/current`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(responses)
  }).then(r => r.json());

  return result;
}

export async function createQuestions(
  questions: Question[],
  eventId: string
): Promise<Event> {
  const result = await fetch(`${API_ENDPOINT}/questions/${eventId}`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      questions: questions
    })
  }).then(r => r.json());

  return result.user;
}

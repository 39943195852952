import { useParams } from "react-router-dom";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { getEvent, Event } from "./api/EventApi";
import { EventForm } from "./EventForm";

export function EditEvent() {
  const { eventId } = useParams<{ eventId: string }>();
  const [event, setEvent] = useState<Event | null>(null);

  useEffectOnce(() => {
    getEvent(eventId || "").then(r => {
      setEvent(r);
    });
  });

  if (!event) {
    return null;
  }

  return <EventForm event={event} />;
}

import React, { Fragment, useState } from "react";
import { Question } from "./api/QuestionsApi";
import Select from "react-select";
import { QuestionType } from "./EventForm";
import { FaTimes } from "react-icons/fa";
import { Checkbox } from "./Checkbox";

export function CreateQuestions({
  questions,
  setQuestions
}: {
  questions: Question[];
  setQuestions: (q: any) => void;
}) {
  const [open, setOpen] = useState<number | null>(0);
  return (
    <>
      {questions
        .sort((a, b) => a.position - b.position)
        .map((question, index) => {
          if (index !== open) {
            return (
              <Fragment>
                <div
                  className="pointer"
                  onClick={() => {
                    setOpen(index);
                  }}
                >
                  {question.question || "Lege vraag"}
                </div>
                <hr />
              </Fragment>
            );
          }

          return (
            <div className="mb-4">
              <div>
                <div className="flex">
                  <label>Vraag</label>
                  <div
                    className="pointer ml-auto"
                    onClick={() => {
                      setQuestions(questions.filter((_, i) => i !== index));
                    }}
                  >
                    <FaTimes />
                  </div>
                </div>

                <input
                  className="input"
                  type="text"
                  placeholder="Vraag"
                  value={question.question}
                  onChange={input => {
                    setQuestions(
                      questions.map((t, i) => {
                        if (i === index) {
                          return {
                            ...t,
                            question: input.target.value
                          };
                        }

                        return t;
                      })
                    );
                  }}
                />
                <label>Type</label>
                <Select
                  options={[
                    { label: "Tekst", value: QuestionType.TEXT },
                    { label: "Nummer", value: QuestionType.NUMBER },
                    { label: "Datum", value: QuestionType.DATE },
                    { label: "Select", value: QuestionType.SELECT }
                  ]}
                  onChange={e => {
                    if (e === null) {
                      return;
                    }

                    setQuestions(
                      questions.map((t, i) => {
                        if (i === index) {
                          return {
                            ...t,
                            type: e.value
                          };
                        }

                        return t;
                      })
                    );
                  }}
                  value={
                    question.type === QuestionType.SELECT
                      ? { label: "Select", value: QuestionType.SELECT }
                      : question.type === QuestionType.TEXT
                      ? { label: "Tekst", value: QuestionType.TEXT }
                      : question.type === QuestionType.NUMBER
                      ? { label: "Nummer", value: QuestionType.NUMBER }
                      : { label: "Datum", value: QuestionType.DATE }
                  }
                />
                <label>Zichtbaar als vraag: </label>
                <Select
                  options={[{ label: "", value: "" }].concat(
                    questions.map(q => {
                      return { label: q.question, value: q.question };
                    })
                  )}
                  onChange={e => {
                    setQuestions(
                      questions.map((t, i) => {
                        if (i === index) {
                          return {
                            ...t,
                            dependsOn: e?.value || null
                          };
                        }

                        return t;
                      })
                    );
                  }}
                  value={{
                    label: question.dependsOn,
                    value: question.dependsOn
                  }}
                />
                <label>Een waarde heeft gelijk aan</label>
                <input
                  className="input"
                  type="text"
                  placeholder="Een waarde heeft gelijk aan"
                  value={question.dependsValue}
                  onChange={input => {
                    setQuestions(
                      questions.map((t, i) => {
                        if (i === index) {
                          return {
                            ...t,
                            dependsValue: input.target.value
                          };
                        }

                        return t;
                      })
                    );
                  }}
                />
                <Checkbox
                  title={"Verplicht veld?"}
                  value={question.required}
                  onClick={checked => {
                    setQuestions(
                      questions.map((t, i) => {
                        if (i === index) {
                          return {
                            ...t,
                            required: checked
                          };
                        }

                        return t;
                      })
                    );
                  }}
                />
                <label>Volgorde</label>
                <input
                  className="input"
                  type="number"
                  placeholder="Volgorde"
                  value={question.position}
                  onChange={input => {
                    setQuestions(
                      questions.map((t, i) => {
                        if (i === index) {
                          return {
                            ...t,
                            position: parseInt(input.target.value)
                          };
                        }

                        return t;
                      })
                    );
                  }}
                />
                <label>Hubspot property</label>
                <input
                  className="input"
                  type="text"
                  placeholder="Hubspot property"
                  value={question.hubspotProperty}
                  onChange={input => {
                    setQuestions(
                      questions.map((t, i) => {
                        if (i === index) {
                          return {
                            ...t,
                            hubspotProperty: input.target.value
                          };
                        }

                        return t;
                      })
                    );
                  }}
                />
                <div className="mt-4" />
                <label>Opties</label>
                {question.options.map((option, optionIndex) => {
                  return (
                    <div className="indent">
                      <div>
                        <div className="flex">
                          <label>Naam</label>
                          <div
                            className="pointer ml-auto"
                            onClick={() => {
                              setQuestions(
                                questions.map((t, i) => {
                                  if (i === index) {
                                    return {
                                      ...t,
                                      options: question.options.filter(
                                        (o, oi) => oi !== optionIndex
                                      )
                                    };
                                  }

                                  return t;
                                })
                              );
                            }}
                          >
                            <FaTimes />
                          </div>
                        </div>
                        <input
                          type="text"
                          className="input"
                          value={option.label}
                          onChange={e => {
                            setQuestions(
                              questions.map((t, i) => {
                                if (i === index) {
                                  return {
                                    ...t,
                                    options: question.options.map((o, oi) => {
                                      if (oi === optionIndex) {
                                        return {
                                          ...o,
                                          label: e.target.value
                                        };
                                      }

                                      return o;
                                    })
                                  };
                                }

                                return t;
                              })
                            );
                          }}
                        />
                      </div>
                      <div>
                        <label>Waarde</label>
                        <input
                          type="text"
                          className="input"
                          value={option.value}
                          onChange={e => {
                            setQuestions(
                              questions.map((t, i) => {
                                if (i === index) {
                                  return {
                                    ...t,
                                    options: question.options.map((o, oi) => {
                                      if (oi === optionIndex) {
                                        return {
                                          ...o,
                                          value: e.target.value
                                        };
                                      }

                                      return o;
                                    })
                                  };
                                }

                                return t;
                              })
                            );
                          }}
                        />
                      </div>
                      <hr />
                    </div>
                  );
                })}
                <div
                  className="mb-4 pointer"
                  onClick={() => {
                    setQuestions(
                      questions.map((t, i) => {
                        if (i === index) {
                          return {
                            ...t,
                            options: question.options.concat({
                              label: "",
                              value: ""
                            })
                          };
                        }

                        return t;
                      })
                    );
                  }}
                >
                  + Optie toevoegen
                </div>
              </div>
              <hr />
            </div>
          );
        })}
      <div
        className="mb-4 pointer"
        onClick={() => {
          setQuestions(
            questions.concat({
              id: "",
              question: "",
              type: QuestionType.TEXT,
              options: [],
              event: "general",
              required: false,
              position: 0,
              dependsOn: null,
              dependsValue: "",
              hubspotProperty: ""
            })
          );
          setOpen(questions.length);
        }}
      >
        + Vraag toevoegen
      </div>
    </>
  );
}

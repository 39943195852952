import { API_ENDPOINT, getHeaders } from "./AuthApi";

export type Event = {
  id: string;
  name: string;
  small_text: string;
  description: string;
  image_banner: string;
  image_square: string;
  max_tickets: number;
  start_date: string;
  end_date: string;
  is_public: boolean;
  color: string;
  tagline: string;
  eventType: string;
  confirmationMail: string;
  linkOnly: boolean;
  disableQuestions: boolean;
  end_order_date: string;
  generalHubspotProperty: string;
  confirmationMailJson: string;
  waitlistMail: string;
  waitlistMailJson: string;
  acceptMail: string;
  acceptMailJson: string;
};

export type UserBookedTickets = {
  booked: {
    id: string;
    ticketId: string;
    ticketName: string;
    eventId: string;
    eventName: string;
    eventStartDate: string;
    eventEndDate: string;
    waitlist: boolean;
  }[];
};

export type BookedTicket = {
  firstName: string;
  lastName: string;
  email: string;
  questions: { question: string; response: string }[];
  ticket_id: string;
  event_id: string;
  id: string;
  showVisit: boolean;
  linkedIn: string;
};

export async function getUserBookedTickets(): Promise<UserBookedTickets> {
  const result = await fetch(`${API_ENDPOINT}/user/tickets`, {
    method: "GET",
    headers: getHeaders()
  }).then(r => r.json());

  return result;
}

export async function getEvent(eventId: string): Promise<Event> {
  const result = await fetch(`${API_ENDPOINT}/events/${eventId}`, {
    method: "GET",
    headers: getHeaders()
  }).then(r => r.json());

  return result.event;
}

export async function deleteEvent(eventId: string): Promise<Event> {
  const result = await fetch(`${API_ENDPOINT}/events/${eventId}`, {
    method: "DELETE",
    headers: getHeaders()
  }).then(r => r.json());

  return result.event;
}

export async function updateEvent(
  id: string,
  name: string,
  small_text: string,
  description: string,
  image_banner: string,
  image_square: string,
  max_tickets: number,
  start_date: string,
  end_date: string,
  is_public: boolean,
  color: string,
  tagline: string,
  eventType: string,
  confirmationMail: string,
  linkOnly: boolean,
  disableQuestions: boolean,
  end_order_date: string,
  generalHubspotProperty: string,
  confirmationMailJson: string,
  waitlistMail: string,
  waitlistMailJson: string,
  acceptMail: string,
  acceptMailJson: string
): Promise<Event> {
  const result = await fetch(`${API_ENDPOINT}/events/${id}`, {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify({
      id: id,
      name: name,
      small_text: small_text,
      description: description,
      image_banner: image_banner,
      image_square: image_square,
      max_tickets: max_tickets,
      start_date: start_date,
      end_date: end_date,
      is_public: is_public,
      color: color,
      tagline: tagline,
      eventType: eventType,
      confirmationMail: confirmationMail,
      linkOnly: linkOnly,
      disableQuestions: disableQuestions,
      end_order_date: end_order_date,
      generalHubspotProperty: generalHubspotProperty,
      confirmationMailJson: confirmationMailJson,
      waitlistMail,
      waitlistMailJson,
      acceptMail,
      acceptMailJson
    })
  }).then(r => r.json());

  return result.event;
}

export async function createEvent(
  name: string,
  small_text: string,
  description: string,
  image_banner: string,
  image_square: string,
  max_tickets: number,
  start_date: string,
  end_date: string,
  is_public: boolean,
  color: string,
  tagline: string,
  eventType: string,
  confirmationMail: string,
  linkOnly: boolean,
  disableQuestions: boolean,
  end_order_date: string,
  generalHubspotProperty: string,
  confirmationMailJson: string,
  waitlistMail: string,
  waitlistMailJson: string,
  acceptMail: string,
  acceptMailJson: string
): Promise<Event> {
  const result = await fetch(`${API_ENDPOINT}/events`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      name: name,
      small_text: small_text,
      description: description,
      image_banner: image_banner,
      image_square: image_square,
      max_tickets: max_tickets,
      start_date: start_date,
      end_date: end_date,
      is_public: is_public,
      color: color,
      tagline: tagline,
      eventType: eventType,
      confirmationMail: confirmationMail,
      linkOnly: linkOnly,
      disableQuestions: disableQuestions,
      end_order_date: end_order_date,
      generalHubspotProperty: generalHubspotProperty,
      confirmationMailJson: confirmationMailJson,
      waitlistMail,
      waitlistMailJson,
      acceptMail,
      acceptMailJson
    })
  }).then(r => r.json());

  return result.event;
}

export async function listEvents(): Promise<Event[]> {
  const result = await fetch(`${API_ENDPOINT}/events`, {
    method: "GET",
    headers: getHeaders()
  }).then(r => r.json());

  return result.events;
}

export async function listBookedTickets(
  eventId: string
): Promise<BookedTicket[]> {
  const result = await fetch(`${API_ENDPOINT}/events/${eventId}/booked`, {
    method: "GET",
    headers: getHeaders()
  }).then(r => r.json());

  return result.tickets;
}

export async function exportEvent(eventId: string): Promise<any> {
  const result = await fetch(`${API_ENDPOINT}/events/${eventId}/export`, {
    method: "GET",
    headers: {
      ...getHeaders(),
      Accept: "text/csv"
    }
  });

  return result.text();
}

export async function remindEvent(
  eventId: string,
  content: string,
  attendedOnly: boolean
): Promise<any> {
  const result = await fetch(`${API_ENDPOINT}/events/${eventId}/remind`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      content: content,
      attendedOnly: attendedOnly
    })
  });

  return result.text();
}

export async function acceptWaitlist(id: string): Promise<void> {
  await fetch(`${API_ENDPOINT}/waitlist`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      id: id
    })
  }).then(r => r.json());
}

export async function acceptWaitlistWithMail(
  id: string,
  email: string
): Promise<void> {
  await fetch(`${API_ENDPOINT}/invites`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      waitlistId: id,
      email: email
    })
  }).then(r => r.json());
}

export async function listWaitlistTickets(
  eventId: string
): Promise<BookedTicket[]> {
  const result = await fetch(`${API_ENDPOINT}/events/${eventId}/waitlist`, {
    method: "GET",
    headers: getHeaders()
  }).then(r => r.json());

  return result.tickets;
}

export async function listBookedTicketAttends(
  eventId: string
): Promise<string[]> {
  const result = await fetch(`${API_ENDPOINT}/events/${eventId}/attends`, {
    method: "GET",
    headers: getHeaders()
  }).then(r => r.json());

  return result.ids;
}

export async function attendUser(
  eventId: string,
  bookedId: string
): Promise<void> {
  await fetch(`${API_ENDPOINT}/events/${eventId}/booked/${bookedId}/attend`, {
    method: "POST",
    headers: getHeaders()
  }).then(r => r.json());
}

export async function cancelTicket(
  eventId: string,
  bookedId: string
): Promise<void> {
  await fetch(`${API_ENDPOINT}/events/${eventId}/booked/${bookedId}/cancel`, {
    method: "POST",
    headers: getHeaders()
  }).then(r => r.json());
}

export async function getUnsubscribes(
  eventId: string
): Promise<{ total: number }> {
  return await fetch(`${API_ENDPOINT}/events/${eventId}/unsubscribes`, {
    method: "GET",
    headers: getHeaders()
  }).then(r => r.json());
}

export async function acceptTicket(id: string): Promise<boolean> {
  const result = await fetch(`${API_ENDPOINT}/invites/${id}`, {
    method: "POST",
    headers: getHeaders()
  }).then(r => r.json());

  return result.total;
}

export async function listEventsAll(): Promise<Event[]> {
  const result = await fetch(`${API_ENDPOINT}/events/all`, {
    method: "GET",
    headers: getHeaders()
  }).then(r => r.json());

  return result.events;
}

import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { createQuestions, getQuestions, Question } from "./api/QuestionsApi";
import { CreateQuestions } from "./CreateQuestions";

export function General() {
  const [questions, setQuestions] = useState<Question[]>([]);

  useEffectOnce(() => {
    getQuestions("general").then(r => {
      setQuestions(r);
    });
  });

  const [saved, setSaved] = useState(false);

  return (
    <form
      onSubmit={async e => {
        e.preventDefault();

        await createQuestions(
          questions
            .filter(q => q.question.trim() !== "")
            .map(q => {
              return {
                ...q,
                event: "general",
                options: q.options.filter(o => o.label.trim() !== "")
              };
            }),
          "general"
        );

        setSaved(true);

        setTimeout(() => {
          setSaved(false);
        }, 1000 * 4);
      }}
    >
      <div className="container">
        <div className="title">Algemeen</div>
        <div className="flex justify-center">
          <div className="w-25">
            <div>
              <label>Vragen</label>
              <CreateQuestions
                questions={questions}
                setQuestions={setQuestions}
              />
              {saved ? (
                <div className="mb-4">
                  <b>Opgeslagen!</b>
                </div>
              ) : null}
              <button className="button" type="submit">
                Opslaan
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

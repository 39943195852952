import React from "react";
import { Question } from "./api/QuestionsApi";
import { QuestionType } from "./EventForm";
import Select from "react-select";
import { DateTime } from "luxon";

export function LoadQuestions({
  questions,
  responses,
  setResponses
}: {
  questions: Question[];
  responses: { question: string; response: string }[];
  setResponses: (r: any) => void;
}) {
  return (
    <>
      {questions
        .sort((a, b) => a.position - b.position)
        .map(question => {
          if (question.dependsOn) {
            const dependsQuestion = questions.find(
              q => q.question === question.dependsOn
            );

            if (dependsQuestion) {
              const dependsResponse = responses.find(
                r => r.question === dependsQuestion.question
              );

              if (
                dependsResponse &&
                dependsResponse.response !== question.dependsValue
              ) {
                return null;
              }
            }
          }
          const found = responses.find(r => r.question === question.question);

          if (question.type === QuestionType.SELECT) {
            return (
              <>
                <label>
                  {question.question}
                  {question.required ? "*" : ""}
                </label>
                <Select
                  options={question.options}
                  onChange={e => {
                    if (e === null) {
                      return;
                    }

                    setResponses(
                      responses.map(r => {
                        if (r.question === question.question) {
                          return {
                            ...r,
                            response: e.value
                          };
                        }

                        return r;
                      })
                    );
                  }}
                  value={question.options.find(
                    o => o.value === found?.response
                  )}
                />
                <div className="mb-4" />
              </>
            );
          }

          return (
            <>
              <label>
                {question.question}
                {question.required ? "*" : ""}
              </label>
              <input
                className="input"
                type={question.type}
                placeholder={question.question}
                value={
                  question.type === "date" && found
                    ? DateTime.fromFormat(found?.response, "dd-LL-yyyy").toFormat(
                        "yyyy-LL-dd"
                      )
                    : found?.response || ""
                }
                onChange={e => {
                  setResponses(
                    responses.map(r => {
                      if (r.question === question.question) {
                        return {
                          ...r,
                          response:
                            question.type === "date"
                              ? DateTime.fromFormat(
                                  e.target.value,
                                  "yyyy-LL-dd"
                                ).toFormat("dd-LL-yyyy")
                              : e.target.value
                        };
                      }

                      return r;
                    })
                  );
                }}
                required={question.required}
              />
            </>
          );
        })}
    </>
  );
}

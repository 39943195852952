import React, { useState } from "react";
import { createEvent, Event, updateEvent } from "./api/EventApi";
import { DateTime } from "luxon";
import { addMedia } from "./api/MediaApi";
import { Checkbox } from "./Checkbox";
import Datetime from "react-datetime";
import moment from "moment";
import {
  createTicket,
  deleteAllTickets,
  listTickets,
  Ticket
} from "./api/TicketApi";
import { useEffectOnce } from "react-use";
import Select from "react-select";
import { createQuestions, getQuestions, Question } from "./api/QuestionsApi";
import { CreateQuestions } from "./CreateQuestions";
import ReactMarkdown from "react-markdown";
import { FaTimes } from "react-icons/fa";
import { SimpleMdeReact } from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import Dialog from "./Dialog";
import { ContentEditor } from "./ContentEditor";
import { useNavigate } from "react-router-dom";
import { defaultContent } from "./DefaultContent";

enum EventColor {
  PINK = "pink",
  YELLOW = "yellow",
  GREEN = "green",
  BLUE = "blue"
}

export enum QuestionType {
  TEXT = "text",
  NUMBER = "number",
  SELECT = "select",
  DATE = "date"
}

export enum EventType {
  NORMAL = "normal",
  EXPERTS = "experts",
  DAY = "day",
  SPECIAL = "special"
}

export function EventForm({
  event,
  copy
}: {
  event: Event | null;
  copy?: boolean;
}) {
  const [name, setName] = useState(event ? event.name : "");
  const [smallText, setSmallText] = useState(event ? event.small_text : "");
  const [description, setDescription] = useState(
    event ? event.description : ""
  );
  const [imageBanner, setImageBanner] = useState(
    event ? event.image_banner : ""
  );
  const [imageSquare, setImageSquare] = useState(
    event ? event.image_square : ""
  );
  const [startDate, setStartDate] = useState(
    event ? DateTime.fromISO(event.start_date) : DateTime.utc()
  );
  const [endDate, setEndDate] = useState(
    event ? DateTime.fromISO(event.end_date) : DateTime.utc()
  );
  const [endOrderDate, setEndOrderDate] = useState(
    event ? DateTime.fromISO(event.end_order_date) : DateTime.utc()
  );
  const [isPublic, setIsPublic] = useState(event ? event.is_public : false);
  const [linkOnly, setLinkOnly] = useState(event ? event.linkOnly : false);
  const [disableQuestions, setDisableQuestions] = useState(
    event ? event.disableQuestions : false
  );
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [color, setColor] = useState(event ? event.color : EventColor.PINK);
  const [generalHubspotProperty, setGeneralHubspotProperty] = useState(
    event ? event.generalHubspotProperty : ""
  );
  const [questions, setQuestions] = useState<Question[]>([]);
  const [tagline, setTagline] = useState(
    event
      ? event.tagline
      : "Niet gevonden wat je zocht? [#AskUsAnything](mailto:info@gentrepreneur.be)"
  );
  const [eventType, setEventType] = useState(
    event ? event.eventType : "normal"
  );
  const [confirmationMail, setConfirmationMail] = useState(
    event && event.confirmationMail
      ? event.confirmationMail
      : "Dag {name}\n\nJe ticket voor {event} is succesvol gereserveerd!\n\nWe zien je graag tot dan!"
  );
  //TODO: add unlayer
  const [confirmationMailJson, setConfirmationMailJson] = useState(
    event ? event.confirmationMailJson : "null"
  );
  const [waitlistMailJson, setWaitlistMailJson] = useState(
    event ? event.waitlistMailJson : "null"
  );
  const [waitlistMail, setWaitlistMail] = useState(
    event ? event.waitlistMail : ""
  );
  const [acceptMailJson, setAcceptMailJson] = useState(
    event ? event.acceptMailJson : "null"
  );
  const [acceptMail, setAcceptMail] = useState(event ? event.acceptMail : "");
  const [mailEditor, setMailEditor] = useState("");
  const mdEditor = React.useRef(null);
  const navigate = useNavigate();

  const [
    selectedSquareImageFile,
    setSelectedSquareImageFile
  ] = useState<File | null>();
  const [
    selectedBannerImageFile,
    setSelectedBannerImageFile
  ] = useState<File | null>();

  useEffectOnce(() => {
    if (event) {
      listTickets(event.id).then(r => {
        setTickets(
          r
            .sort((a, b) =>
              a.slot && b.slot
                ? DateTime.fromISO(a.slot) < DateTime.fromISO(b.slot)
                  ? -1
                  : 1
                : 0
            )
            .sort((a, b) => (a.name < b.name ? -1 : 1))
        );
      });

      getQuestions(event.id).then(r => {
        setQuestions(r);
      });
    }
  });

  let design = JSON.stringify(defaultContent);

  if (mailEditor === "confirm" && confirmationMailJson !== "null") {
    design = confirmationMailJson;
  }

  if (mailEditor === "waitlist" && waitlistMailJson !== "null") {
    design = waitlistMailJson;
  }

  if (mailEditor === "accept" && acceptMailJson !== "null") {
    design = acceptMailJson;
  }

  return (
    <form
      onSubmit={async e => {
        e.preventDefault();

        let tmpImageBanner = imageBanner;
        let tmpImageSquare = imageSquare;

        if (selectedBannerImageFile) {
          const pathResult = await addMedia(selectedBannerImageFile);

          if (pathResult && typeof pathResult !== "string" && pathResult.path) {
            tmpImageBanner = pathResult.path;
          }
        }

        if (selectedSquareImageFile) {
          const pathResult = await addMedia(selectedSquareImageFile);

          if (pathResult && typeof pathResult !== "string" && pathResult.path) {
            tmpImageSquare = pathResult.path;
          }
        }

        let eventId = event?.id || "";

        if (event && !copy) {
          await updateEvent(
            event.id,
            name,
            smallText,
            description,
            tmpImageBanner,
            tmpImageSquare,
            0,
            startDate.toUTC().toISO(),
            endDate.toUTC().toISO(),
            isPublic,
            color,
            tagline,
            eventType,
            confirmationMail,
            linkOnly,
            disableQuestions,
            endOrderDate.toUTC().toISO(),
            generalHubspotProperty,
            confirmationMailJson,
            waitlistMail,
            waitlistMailJson,
            acceptMail,
            acceptMailJson
          );
        } else {
          const newEvent = await createEvent(
            name,
            smallText,
            description,
            tmpImageBanner,
            tmpImageSquare,
            0,
            startDate.toUTC().toISO(),
            endDate.toUTC().toISO(),
            isPublic,
            color,
            tagline,
            eventType,
            confirmationMail,
            linkOnly,
            disableQuestions,
            endOrderDate.toUTC().toISO(),
            generalHubspotProperty,
            confirmationMailJson,
            waitlistMail,
            waitlistMailJson,
            acceptMail,
            acceptMailJson
          );

          eventId = newEvent.id;
        }

        try {
          await deleteAllTickets(eventId);
        } catch (e) {}

        for (const ticket of tickets.filter(t => t.name.trim() !== "")) {
          await createTicket(
            eventId,
            ticket.name,
            ticket.description,
            ticket.max_tickets,
            ticket.slot,
            copy ? "" : ticket.id || "",
            ticket.hubspotProperty
          );
        }

        await createQuestions(
          questions
            .filter(q => q.question.trim() !== "")
            .map(q => {
              return {
                ...q,
                event: eventId,
                options: q.options.filter(o => o.label.trim() !== "")
              };
            }),
          eventId
        );

        navigate("/admin/events");
      }}
    >
      <div className="container">
        <div className="title">
          {event ? "Event bijwerken" : "Event aanmaken"}
        </div>
        <div className="flex justify-center">
          <div className="w-25">
            <div>
              <label>Naam</label>
              <input
                className="input"
                type="text"
                placeholder="Naam"
                value={name}
                onChange={input => {
                  setName(input.target.value);
                }}
                required={true}
              />
              <label>Korte beschrijving</label>
              <input
                className="input"
                type="text"
                placeholder="Korte beschrijving"
                value={smallText}
                onChange={input => {
                  setSmallText(input.target.value);
                }}
                required={true}
              />
              <div className="mb-4" />
              <label>Bevestigingsmail</label>
              <div
                className="button mb-4"
                onClick={() => {
                  setMailEditor("confirm");
                }}
              >
                Editor openen
              </div>
              <label>Wachtlijst mail</label>
              <div
                className="button mb-4"
                onClick={() => {
                  setMailEditor("waitlist");
                }}
              >
                Editor openen
              </div>
              <label>Accepteer wachtlijst mail</label>
              <div
                className="button mb-4"
                onClick={() => {
                  setMailEditor("accept");
                }}
              >
                Editor openen
              </div>
              <label>Kleur</label>
              <Select
                options={[
                  { label: EventColor.PINK, value: EventColor.PINK },
                  { label: EventColor.GREEN, value: EventColor.GREEN },
                  { label: EventColor.BLUE, value: EventColor.BLUE },
                  { label: EventColor.YELLOW, value: EventColor.YELLOW }
                ]}
                onChange={e => {
                  if (e === null) {
                    return;
                  }

                  setColor(e.value);
                }}
                value={{ label: color, value: color }}
              />
              <label>Event type</label>
              <Select
                options={[
                  { label: EventType.NORMAL, value: EventType.NORMAL },
                  { label: EventType.EXPERTS, value: EventType.EXPERTS },
                  { label: EventType.DAY, value: EventType.DAY },
                  { label: EventType.SPECIAL, value: EventType.SPECIAL }
                ]}
                onChange={e => {
                  if (e === null) {
                    return;
                  }

                  setEventType(e.value);
                }}
                value={{ label: eventType, value: eventType }}
              />
              <label>Start</label>
              <Datetime
                locale="nl-be"
                onChange={item => {
                  setStartDate(DateTime.fromISO(moment(item).toISOString()));
                }}
                timeFormat={true}
                dateFormat={true}
                value={moment(startDate.toISO())}
              />
              <label>Einde</label>
              <Datetime
                locale="nl-be"
                timeFormat={true}
                dateFormat={true}
                onChange={item => {
                  setEndDate(DateTime.fromISO(moment(item).toISOString()));
                }}
                value={moment(endDate.toISO())}
              />
              <label>Einde om te bestellen</label>
              <Datetime
                locale="nl-be"
                timeFormat={true}
                dateFormat={true}
                onChange={item => {
                  setEndOrderDate(DateTime.fromISO(moment(item).toISOString()));
                }}
                value={moment(endOrderDate.toISO())}
              />
              <label>Tagline</label>
              <input
                className="input"
                type="text"
                placeholder="Tagline"
                value={tagline}
                onChange={input => {
                  setTagline(input.target.value);
                }}
              />
              <label>Hubspot property over het jaar</label>
              <input
                className="input"
                type="text"
                placeholder="Hubspot property"
                value={generalHubspotProperty}
                onChange={input => {
                  setGeneralHubspotProperty(input.target.value);
                }}
              />
              <label>Ticket date override</label>
              <Datetime
                locale="nl-be"
                onChange={item => {
                  setTickets(
                    tickets.map(t => {
                      return {
                        ...t,
                        slot: t.slot
                          ? moment(item)
                              .set({
                                hour: moment(t.slot).hour(),
                                minute: moment(t.slot).minute()
                              })
                              .toISOString()
                          : moment(item).toISOString()
                      };
                    })
                  );
                }}
                timeFormat={true}
                dateFormat={true}
              />
              <label>Tickets</label>
              {tickets.map((ticket, index) => {
                return (
                  <div className="mb-4">
                    <div>
                      <div className="flex">
                        <label>Naam</label>
                        <div
                          className="pointer ml-auto"
                          onClick={() => {
                            setTickets(tickets.filter((_, i) => i !== index));
                          }}
                        >
                          <FaTimes />
                        </div>
                      </div>
                      <input
                        className="input"
                        type="text"
                        placeholder="Naam"
                        value={ticket.name}
                        onChange={input => {
                          setTickets(
                            tickets.map((t, i) => {
                              if (i === index) {
                                return {
                                  ...t,
                                  name: input.target.value
                                };
                              }

                              return t;
                            })
                          );
                        }}
                      />
                      <label>Beschrijving</label>
                      <input
                        className="input"
                        type="text"
                        placeholder="Beschrijving"
                        value={ticket.description}
                        onChange={input => {
                          setTickets(
                            tickets.map((t, i) => {
                              if (i === index) {
                                return {
                                  ...t,
                                  description: input.target.value
                                };
                              }

                              return t;
                            })
                          );
                        }}
                      />
                      <label>Maximale tickets</label>
                      <input
                        className="input"
                        type="number"
                        placeholder="Maximale tickets"
                        value={ticket.max_tickets}
                        onChange={input => {
                          setTickets(
                            tickets.map((t, i) => {
                              if (i === index) {
                                return {
                                  ...t,
                                  max_tickets: parseInt(input.target.value)
                                };
                              }

                              return t;
                            })
                          );
                        }}
                      />
                      <label>Tijdslot (Optioneel)</label>
                      <Datetime
                        locale="nl-be"
                        timeFormat={true}
                        dateFormat={true}
                        onChange={input => {
                          setTickets(
                            tickets.map((t, i) => {
                              if (i === index) {
                                return {
                                  ...t,
                                  slot: moment(input).toISOString()
                                };
                              }

                              return t;
                            })
                          );
                        }}
                        value={ticket.slot ? moment(ticket.slot) : undefined}
                      />
                      <label>Hubspot property</label>
                      <input
                        className="input"
                        type="text"
                        placeholder="Hubspot property"
                        value={ticket.hubspotProperty}
                        onChange={input => {
                          setTickets(
                            tickets.map((t, i) => {
                              if (i === index) {
                                return {
                                  ...t,
                                  hubspotProperty: input.target.value
                                };
                              }

                              return t;
                            })
                          );
                        }}
                      />
                    </div>
                    <hr />
                  </div>
                );
              })}
              <div
                className="mb-4 pointer"
                onClick={() => {
                  setTickets(
                    tickets.concat({
                      id: "",
                      name: "",
                      description: "",
                      max_tickets: 0,
                      event_id: "",
                      slot: null,
                      hubspotProperty: ""
                    })
                  );
                }}
              >
                + Ticket toevoegen
              </div>
              <label>Extra vragen</label>
              <CreateQuestions
                questions={questions}
                setQuestions={setQuestions}
              />

              <div className="flex flex-column">
                <button className="button" type="submit">
                  {event ? "Event bijwerken" : "Event aanmaken"}
                </button>
                {event ? (
                  <a
                    className="button mt-4"
                    href={`/events/${event.id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Preview bekijken
                  </a>
                ) : null}
              </div>
            </div>
          </div>
          <div
            className={`w-50`}
            style={{ marginLeft: "20px", marginRight: "20px" }}
          >
            <SimpleMdeReact value={description} onChange={setDescription} />
            <h2>Preview text</h2>
            <div className={`event ${color}`}>
              <ReactMarkdown>{description}</ReactMarkdown>
            </div>
          </div>
          <div className="w-25">
            <label>Banner</label>
            {selectedBannerImageFile ? (
              <img
                alt={name}
                className="edit-img"
                src={URL.createObjectURL(selectedBannerImageFile)}
              />
            ) : null}
            {imageBanner && !selectedBannerImageFile ? (
              <img alt={name} className="" src={imageBanner} />
            ) : null}
            <input
              type="file"
              className="input"
              placeholder="Upload afbeelding"
              onChange={e => {
                if (e.target.files && e.target.files.length > 0) {
                  setSelectedBannerImageFile(e.target.files[0]);
                }
              }}
            />
            <label>Afbeelding</label>
            {selectedSquareImageFile ? (
              <img
                alt={name}
                className="edit-img"
                src={URL.createObjectURL(selectedSquareImageFile)}
              />
            ) : null}
            {imageSquare && !selectedSquareImageFile ? (
              <img alt={name} className="" src={imageSquare} />
            ) : null}
            <input
              type="file"
              className="input"
              placeholder="Upload afbeelding"
              onChange={e => {
                if (e.target.files && e.target.files.length > 0) {
                  setSelectedSquareImageFile(e.target.files[0]);
                }
              }}
            />
            <Checkbox
              title="Is publiek"
              value={isPublic}
              onClick={value => {
                setIsPublic(value);
              }}
            />
            <Checkbox
              title="Link only"
              value={linkOnly}
              onClick={value => {
                setLinkOnly(value);
              }}
            />
            <Checkbox
              title="Algemene vragen uitschakelen"
              value={disableQuestions}
              onClick={value => {
                setDisableQuestions(value);
              }}
            />
          </div>
        </div>
      </div>
      <Dialog
        isOpen={mailEditor !== ""}
        onRequestClose={() => {
          setMailEditor("");
        }}
        title={"Mail editor"}
        size={"large"}
        padding={true}
      >
        <ContentEditor
          design={design}
          save={({ html, design }) => {
            if (mailEditor === "confirm") {
              setConfirmationMail(html);
              setConfirmationMailJson(design);
            }

            if (mailEditor === "waitlist") {
              setWaitlistMail(html);
              setWaitlistMailJson(design);
            }

            if (mailEditor === "accept") {
              setAcceptMail(html);
              setAcceptMailJson(design);
            }

            setMailEditor("");
          }}
          cancel={() => {
            setMailEditor("");
          }}
        />
      </Dialog>
    </form>
  );
}

import { API_ENDPOINT, getHeaders } from "./AuthApi";

export type Media = {
  id: string;
  url: string;
  name: string;
};

export async function addMedia(file: File): Promise<{ path: string } | string> {
  const formData = new FormData();
  formData.append("media", file);

  const result = await fetch(`${API_ENDPOINT}/media/upload`, {
    method: "POST",
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`
    },
    body: formData
  }).then(r => r.json());

  if (result.message) {
    return result.message;
  }

  return result;
}

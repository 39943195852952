export type Token = {
  firstName: string;
  lastName: string;
  email: string;
  admin: boolean;
  userId: string;
};

export type CustomResponse = {
  error?: string;
  ok: boolean;
};

export function getHeaders() {
  return {
    Authorization: `JWT ${localStorage.getItem("token")}`,
    "Content-type": "application/json"
  };
}

export const API_ENDPOINT = process.env.REACT_APP_API_URL;

export async function register(
  firstName: string,
  lastName: string,
  email: string,
  password: string
): Promise<CustomResponse> {
  const result = await fetch(`${API_ENDPOINT}/auth/register`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password
    })
  }).then(r => r.json());

  if (result.message) {
    return {
      ok: false,
      error: result.message
    };
  }

  if (result.token) {
    localStorage.setItem("token", result.token);
    return { ok: true };
  }

  return { ok: false, error: "Er is iets foutgelopen bij de registratie." };
}

export function redirectIfNotAuthenticated() {
  const token = localStorage.getItem("token");

  if (token === null) {
    return true;
  }

  try {
    const token_parts = token.split(/\./);
    const token_decoded = JSON.parse(window.atob(token_parts[1]));

    return new Date(token_decoded.exp * 1000) < new Date();
  } catch (e) {
    return true;
  }
}

export async function login(
  email: string,
  password: string
): Promise<CustomResponse> {
  const result = await fetch(`${API_ENDPOINT}/auth/login`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ email: email, password: password })
  }).then(r => r.json());

  if (result.message) {
    return {
      ok: false,
      error: result.message
    };
  }

  if (result.token) {
    localStorage.setItem("token", result.token);
    return { ok: true };
  }

  return {
    ok: false,
    error: "Er is iets foutgelopen bij de login."
  };
}

export async function forgotPassword(email: string): Promise<string> {
  const result = await fetch(`${API_ENDPOINT}/auth/forgot`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ email: email })
  }).then(r => r.json());

  return result.message;
}

export async function resetPassword(
  password: string,
  id: string
): Promise<string> {
  const result = await fetch(`${API_ENDPOINT}/auth/password`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ password: password, id: id })
  }).then(r => r.json());

  return result.message;
}

export function loggedIn() {
  return !redirectIfNotAuthenticated();
}

export function getDecodedToken(): Token {
  const token = localStorage.getItem("token");

  if (token === null) {
    return {
      userId: "",
      firstName: "",
      lastName: "",
      email: "",
      admin: false
    };
  }

  const token_parts = token.split(/\./);
  return JSON.parse(window.atob(token_parts[1]));
}

export function isAdmin() {
  const token = getDecodedToken();

  return token.admin;
}

import "./Home.css";
import { useParams } from "react-router-dom";
import { acceptTicket } from "./api/EventApi";
import { useState } from "react";

export function AcceptTicket() {
  const [done, setDone] = useState(false);
  const [late, setLate] = useState(false);
  const { inviteId } = useParams<{
    inviteId: string;
  }>();

  if (late) {
    return (
      <div className="home container">
        <div className="title">Helaas te laat!</div>
        <div className="subtitle">
          Helaas ben je te laat met het bevestigen.
        </div>
      </div>
    );
  }

  return done ? (
    <div className="home container">
      <div className="title">Ticket bemachtigd</div>
      <div className="subtitle">
        Kijk snel naar je mails want je ticket komt er aan!
      </div>
    </div>
  ) : (
    <div className="home container">
      <div className="title">Ticket bemachtigen</div>
      <div className="subtitle">
        Gelieve op de onderstaande knop te klikken om te bevestigen.
      </div>
      <div
        className="button ml-auto mr-auto mt-4"
        onClick={async () => {
          const result = await acceptTicket(inviteId || "");
          if (result) {
            setDone(true);
          } else {
            setLate(true);
          }
        }}
      >
        Bevestigen
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import "./Checkbox.css";

export function Checkbox({
  title,
  value,
  onClick,
  disabled = false
}: {
  title: string;
  value: boolean;
  onClick: (clicked: boolean) => void;
  disabled?: boolean;
}) {
  const [checked, setChecked] = useState(value);

  useEffect(() => {
    if (disabled) {
      return;
    }

    onClick(checked);
  }, [checked]);

  useEffect(() => {
    if (disabled) {
      return;
    }

    setChecked(value);
  }, [value]);

  return (
    <label
      className="checkbox-container"
      onClick={e => {
        e.preventDefault();
        if (disabled) {
          return;
        }

        setChecked(!checked);
      }}
    >
      {title}
      <input type="checkbox" checked={checked} disabled={disabled} />
      <span className={`checkmark ${disabled ? "disabled" : ""}`} />
    </label>
  );
}

import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CustomResponse, loggedIn, register } from "./api/AuthApi";
import { createResponse, getQuestions, Question } from "./api/QuestionsApi";
import { useEffectOnce } from "react-use";
import { LoadQuestions } from "./LoadQuestions";

export function Register({
  setAuthenticated
}: {
  setAuthenticated: (b: boolean) => void;
}) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [response, setResponse] = useState<CustomResponse | null>(null);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [responses, setResponses] = useState<
    { question: string; response: string }[]
  >([]);
  const navigate = useNavigate();

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const redirectSearch = searchParams.get("redirect");
  const redirectUrl = redirectSearch
    ? decodeURIComponent(redirectSearch)
    : "/user/profile";

  useEffectOnce(() => {
    getQuestions("general").then(r => {
      setQuestions(r.reverse());
      setResponses(
        r.reverse().map(q => {
          return {
            question: q.question,
            response: ""
          };
        })
      );
    });
  });

  return (
    <div className="account container flex flex-column justify-center">
      <div className="title">Registreren</div>
      <form
        onSubmit={e => {
          e.preventDefault();

          register(firstName, lastName, email, password).then(async r => {
            setResponse(r);

            if (r.ok) {
              await createResponse({ responses: responses });

              setAuthenticated(true);
            }

            if ((response && response.ok) || loggedIn()) {
              navigate(redirectUrl);
            }
          });
        }}
      >
        <div className="flex items-center flex-column">
          <label>E-mail</label>
          <input
            className="input"
            type="text"
            placeholder="E-mail"
            value={email}
            onChange={e => {
              setEmail(e.target.value);
            }}
            required
          />
          <label>Voornaam</label>
          <input
            className="input"
            type="text"
            placeholder="Voornaam"
            value={firstName}
            onChange={e => {
              setFirstName(e.target.value);
            }}
            required
          />
          <label>Achternaam</label>
          <input
            className="input"
            type="text"
            placeholder="Achternaam"
            value={lastName}
            onChange={e => {
              setLastName(e.target.value);
            }}
            required
          />
          <label>Wachtwoord</label>
          <input
            className="input"
            type="password"
            placeholder="Wachtwoord"
            value={password}
            onChange={e => {
              setPassword(e.target.value);
            }}
            required
          />
          <LoadQuestions
            questions={questions}
            responses={responses}
            setResponses={setResponses}
          />
          {(response && response.ok) || loggedIn() ? null : (
            <div className="error mb-4">{response?.error}</div>
          )}
          <button type="submit" className="button">
            Registreren
          </button>
          <div className="mt-4">
            <Link to="/login">Al een account?</Link>
          </div>
        </div>
      </form>
    </div>
  );
}

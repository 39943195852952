import { useState } from "react";
import { useEffectOnce } from "react-use";
import { FaCopy, FaEye, FaPen, FaTimes } from "react-icons/fa";
import {
  BookedTicket,
  deleteEvent,
  Event,
  listBookedTickets,
  listEventsAll
} from "./api/EventApi";
import { DateTime } from "luxon";
import { AiOutlineMail } from "react-icons/ai";
import { listTickets, Ticket } from "./api/TicketApi";
import { EventType } from "./EventForm";
import { useNavigate } from "react-router-dom";

export function Events() {
  const [events, setEvents] = useState<Event[]>([]);
  const [filter, setFilter] = useState("");
  const [tickets, setTickets] = useState<Record<string, Ticket[]>>({});
  const [booked, setBooked] = useState<Record<string, BookedTicket[]>>({});
  const navigate = useNavigate();

  useEffectOnce(() => {
    listEventsAll().then(async r => {
      setEvents(
        r.sort((a, b) => {
          return (
            DateTime.fromISO(b.start_date).toMillis() -
            DateTime.fromISO(a.start_date).toMillis()
          );
        })
      );

      const allTickets: Record<string, Ticket[]> = {};

      for (const item of r) {
        allTickets[item.id] = await listTickets(item.id);
      }

      setTickets(allTickets);

      const allBooked: Record<string, BookedTicket[]> = {};

      for (const item of r) {
        allBooked[item.id] = await listBookedTickets(item.id);
      }

      setBooked(allBooked);
    });
  });

  return (
    <div className="container">
      <div className="title">Events</div>
      <div className="flex">
        <div
          className="ml-auto button"
          onClick={() => {
            navigate("/admin/events/new");
          }}
        >
          Maak event aan
        </div>
      </div>

      <input
        className="input"
        type="text"
        placeholder={"Naam"}
        value={filter}
        onChange={input => {
          setFilter(input.target.value);
        }}
      />

      {events.length === 0 ? (
        "Momenteel nog geen events beschikbaar"
      ) : (
        <table className="table ">
          <thead>
            <tr>
              <th>Naam</th>
              <th>Korte beschrijving</th>
              <th>Start</th>
              <th>Einde</th>
              <th>Tickets</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {events
              .filter(e =>
                `${e.name}`.toLowerCase().includes(filter.toLowerCase())
              )
              .map(event => {
                const etickets = tickets[event.id] || [];
                const ebooked = booked[event.id] || [];

                return (
                  <tr key={event.id}>
                    <td>{event.name}</td>
                    <td>{event.small_text}</td>
                    <td>
                      {DateTime.fromISO(event.start_date).toFormat(
                        "dd/LL/y HH'.'mm 'uur'"
                      )}
                    </td>
                    <td>
                      {DateTime.fromISO(event.end_date).toFormat(
                        "dd/LL/y HH'.'mm 'uur'"
                      )}
                    </td>
                    <td>
                      {event.eventType === EventType.EXPERTS ? (
                        <div>
                          <b>Totaal:</b> {ebooked.length}
                        </div>
                      ) : (
                        etickets.map(t => {
                          const cbooked = ebooked.filter(
                            b => b.ticket_id === t.id
                          );

                          return (
                            <div>
                              <b>
                                {t.name}{" "}
                                {event.eventType === EventType.SPECIAL
                                  ? ` - ${t.description}`
                                  : ""}
                              </b>{" "}
                              x {cbooked.length}
                            </div>
                          );
                        })
                      )}
                    </td>
                    <td>
                      <div className="flex">
                        <div
                          className="button small w-fit ml-auto"
                          onClick={async () => {
                            navigate(`/admin/events/${event.id}/view`);
                          }}
                        >
                          <FaEye />
                        </div>
                        <div
                          className="button small w-fit ml-4"
                          onClick={async () => {
                            navigate(`/admin/events/${event.id}`);
                          }}
                        >
                          <FaPen />
                        </div>
                        <div
                          className="button small w-fit ml-4"
                          onClick={async () => {
                            navigate(`/admin/events/${event.id}/copy`);
                          }}
                        >
                          <FaCopy />
                        </div>
                        <div
                          className="button small w-fit ml-4"
                          onClick={async () => {
                            navigate(`/admin/events/${event.id}/remind`);
                          }}
                        >
                          <AiOutlineMail />
                        </div>
                        <div
                          className="button small w-fit pink"
                          onClick={async () => {
                            if (
                              // eslint-disable-next-line no-restricted-globals
                              confirm(
                                "Ben je zeker dat je dit event wilt verwijderen?"
                              )
                            ) {
                              await deleteEvent(event.id);
                              setEvents(events.filter(e => e.id !== event.id));
                            }
                          }}
                        >
                          <FaTimes />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
    </div>
  );
}

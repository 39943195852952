import React, { useEffect, useState } from "react";
import "./App.css";
import { Header } from "./Header";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Home } from "./Home";
import { Login } from "./Login";
import { Register } from "./Register";
import { Forgot } from "./Forgot";
import { loggedIn } from "./api/AuthApi";
import { Users } from "./Users";
import { Events } from "./Events";
import { CreateEvent } from "./CreateEvent";
import { EditEvent } from "./EditEvent";
import "react-datetime/css/react-datetime.css";
import { EventView } from "./EventView";
import { EventAdminView } from "./EventAdminView";
import { General } from "./General";
import "moment/locale/nl-be";
import { CopyEvent } from "./CopyEvent";
import { RemindEventForm } from "./RemindEventForm";
import { CancelTicket } from "./CancelTicket";
import { UpcomingEvents } from "./UpcomingEvents";
import { PastEvents } from "./PastEvents";
import { Profile } from "./Profile";
import { Reset } from "./Reset";
import { Visitors } from "./Visitors";
import { AcceptTicket } from "./AcceptTicket";
import { ScrollToTop } from "./ScrollToTop";

function App() {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    if (loggedIn()) {
      setAuthenticated(true);
    }
  }, []);

  return (
    <div>
      <Router>
        <ScrollToTop />
        <Header authenticated={authenticated} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/invites/:inviteId" element={<AcceptTicket />} />
          <Route
            path="/booking/:eventId/:ticketId/cancel"
            element={<CancelTicket />}
          />
          <Route
            path="/events/:eventId"
            element={<EventView setAuthenticated={setAuthenticated} />}
          />
          <Route
            path="/login"
            element={<Login setAuthenticated={setAuthenticated} />}
          />
          <Route
            path="/register"
            element={<Register setAuthenticated={setAuthenticated} />}
          />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/reset/:id" element={<Reset />} />
          <Route path="/user/visitors/:eventId" element={<Visitors />} />
          <Route path="/user/events" element={<UpcomingEvents />} />
          <Route path="/user/past" element={<PastEvents />} />
          <Route path="/user/profile" element={<Profile />} />
          <Route path="/admin/general" element={<General />} />
          <Route path="/admin/events" element={<Events />} />
          <Route path="/admin/events/new" element={<CreateEvent />} />
          <Route path="/admin/events/:eventId" element={<EditEvent />} />
          <Route
            path="/admin/events/:eventId/view"
            element={<EventAdminView />}
          />
          <Route path="/admin/events/:eventId/copy" element={<CopyEvent />} />
          <Route
            path="/admin/events/:eventId/remind"
            element={<RemindEventForm />}
          />
          <Route path="/admin/users" element={<Users />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

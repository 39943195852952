import { useState } from "react";
import { useEffectOnce } from "react-use";
import { FaCheck, FaTimes } from "react-icons/fa";
import {
  Event,
  getEvent,
  BookedTicket,
  listBookedTickets,
  listBookedTicketAttends,
  attendUser,
  cancelTicket,
  listWaitlistTickets,
  acceptWaitlist,
  exportEvent,
  acceptWaitlistWithMail,
  getUnsubscribes
} from "./api/EventApi";
import { useParams } from "react-router-dom";
import { listTickets, Ticket } from "./api/TicketApi";
import { isArray } from "lodash";
import { DateTime } from "luxon";
import { Checkbox } from "./Checkbox";
import { EventType } from "./EventForm";
import Dialog from "./Dialog";

function getSumPerType(tickets: Ticket[], bookedTickets: BookedTicket[]) {
  const data: Record<string, number> = {};

  for (const ticket of tickets) {
    data[ticket.name] = 0;
  }

  for (const booked of bookedTickets) {
    const foundTicket = tickets.find(t => t.id === booked.ticket_id);

    if (!foundTicket) {
      continue;
    }

    const foundData = data[foundTicket.name];

    data[foundTicket.name] = foundData ? foundData + 1 : 1;
  }

  return data;
}

export function EventAdminView() {
  const { eventId } = useParams<{ eventId: string }>();
  const [event, setEvent] = useState<Event | null>(null);
  const [booked, setBooked] = useState<BookedTicket[]>([]);
  const [waitlist, setWaitlist] = useState<BookedTicket[]>([]);
  const [attendees, setAttendees] = useState<string[]>([]);
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [filter, setFilter] = useState("");
  const [showDetails, setShowDetails] = useState(true);
  const [showDetailsWaitlist, setShowDetailsWaitlist] = useState(true);
  const [waitlistPopup, setWaitlistPopup] = useState<BookedTicket | null>(null);
  const [unsubscribes, setUnsubscribes] = useState(0);

  useEffectOnce(() => {
    getEvent(eventId || "").then(r => {
      setEvent(r);
    });

    listBookedTickets(eventId || "").then(r => {
      setBooked(r);
    });

    listBookedTicketAttends(eventId || "").then(r => {
      setAttendees(r);
    });

    listWaitlistTickets(eventId || "").then(r => {
      setWaitlist(r);
    });

    listTickets(eventId || "").then(r => {
      setTickets(r);
    });

    getUnsubscribes(eventId || "").then(r => {
      setUnsubscribes(r.total);
    });
  });

  if (!event) {
    return null;
  }

  return (
    <div className="container">
      <div className="title">Bezoekers van {event.name}</div>
      <div
        className="button"
        onClick={async () => {
          const result = await exportEvent(eventId || "");

          const downloadLink = document.createElement("a");
          downloadLink.href =
            "data:text/csv;charset=utf-8," + encodeURI(result);
          downloadLink.target = "_blank";
          downloadLink.download = "export.csv";
          downloadLink.click();
        }}
      >
        Exporteer data
      </div>
      <input
        className="input"
        type="text"
        placeholder={"Naam"}
        value={filter}
        onChange={input => {
          setFilter(input.target.value);
        }}
      />
      <Checkbox
        title={"Toon details"}
        value={showDetails}
        onClick={setShowDetails}
      />

      <div>
        <b>totaal uitgeschreven:</b> {unsubscribes}
      </div>

      {booked.length === 0 ? (
        "Momenteel nog geen bezoekers beschikbaar"
      ) : (
        <div>
          <div>
            {event.eventType === EventType.EXPERTS
              ? Object.entries(getSumPerType(tickets, booked)).map(
                  ([key, value]) => {
                    return (
                      <div>
                        <b>Aantal van {key}:</b> {value}
                      </div>
                    );
                  }
                )
              : tickets.map(t => {
                  return (
                    <div>
                      <b>
                        Aantal van {t.name}{" "}
                        {event.eventType === EventType.SPECIAL
                          ? ` - ${t.description}`
                          : ""}
                        :
                      </b>{" "}
                      {
                        attendees.filter(a =>
                          booked
                            .filter(b => b.ticket_id === t.id)
                            .map(b => b.id)
                            .includes(a)
                        ).length
                      }
                      /{booked.filter(b => b.ticket_id === t.id).length}
                    </div>
                  );
                })}
          </div>
          <table className="table ">
            <thead>
              <tr>
                <th>Naam</th>
                <th>E-mail</th>
                <th>Ticket</th>
                <th>Vragen</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {booked
                .filter(e =>
                  `${e.firstName} ${e.lastName}`
                    .toLowerCase()
                    .includes(filter.toLowerCase())
                )
                .map(booking => {
                  const ticket = tickets.find(t => t.id === booking.ticket_id);

                  return (
                    <tr key={booking.id}>
                      <td>
                        {booking.firstName} {booking.lastName}
                      </td>
                      <td>{booking.email}</td>
                      <td>
                        {ticket?.name}{" "}
                        {event.eventType === EventType.SPECIAL
                          ? ` - ${ticket?.description} `
                          : ""}
                        {ticket?.slot
                          ? DateTime.fromISO(ticket.slot).toFormat(
                              "HH'.'mm 'uur'"
                            )
                          : null}
                      </td>
                      <td>
                        {showDetails && isArray(booking.questions)
                          ? booking.questions.map(q => {
                              return (
                                <>
                                  <b>{q.question}</b>
                                  <div>{q.response}</div>
                                  <div className="mb-4" />
                                </>
                              );
                            })
                          : null}
                      </td>
                      <td>
                        <div className="flex">
                          <div
                            className={`button small w-fit ml-auto ${
                              attendees.includes(booking.id) ? "green" : ""
                            }`}
                            onClick={async () => {
                              await attendUser(eventId || "", booking.id);

                              if (attendees.includes(booking.id)) {
                                setAttendees(
                                  attendees.filter(a => a !== booking.id)
                                );
                              } else {
                                setAttendees(attendees.concat(booking.id));
                              }
                            }}
                          >
                            <FaCheck />
                          </div>
                          <div
                            className={`button small w-fit ml-4 pink`}
                            onClick={async () => {
                              if (
                                // eslint-disable-next-line no-restricted-globals
                                confirm(
                                  "Ben je zeker dat je dit wilt verwijderen?"
                                )
                              ) {
                                await cancelTicket(eventId || "", booking.id);
                                setAttendees(
                                  attendees.filter(a => a !== booking.id)
                                );
                                setBooked(
                                  booked.filter(b => b.id !== booking.id)
                                );
                              }
                            }}
                          >
                            <FaTimes />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}

      {waitlist.length === 0 ? (
        "Momenteel nog geen bezoekers op de wachtlijst"
      ) : (
        <div>
          <h2>Wachtlijst</h2>
          <Checkbox
            title={"Toon details"}
            value={showDetailsWaitlist}
            onClick={setShowDetailsWaitlist}
          />
          <div>
            {tickets.map(t => {
              return (
                <div>
                  <b>Aantal van {t.name}:</b>{" "}
                  {waitlist.filter(b => b.ticket_id === t.id).length}
                </div>
              );
            })}
          </div>
          <table className="table mt-4">
            <thead>
              <tr>
                <th>Naam</th>
                <th>E-mail</th>
                <th>Ticket</th>
                <th>Vragen</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {waitlist
                .filter(e =>
                  `${e.firstName} ${e.lastName}`
                    .toLowerCase()
                    .includes(filter.toLowerCase())
                )
                .map(booking => {
                  const ticket = tickets.find(t => t.id === booking.ticket_id);

                  return (
                    <tr key={booking.id}>
                      <td>
                        {booking.firstName} {booking.lastName}
                      </td>
                      <td>{booking.email}</td>
                      <td>
                        <b>wachtlijst</b> - {ticket?.name}
                      </td>
                      <td>
                        {showDetailsWaitlist && isArray(booking.questions)
                          ? booking.questions.map(q => {
                              return (
                                <>
                                  <b>{q.question}</b>
                                  <div>{q.response}</div>
                                  <div className="mb-4" />
                                </>
                              );
                            })
                          : null}
                      </td>
                      <td>
                        <div className="flex">
                          <div
                            className={`button small w-fit ml-auto ${
                              attendees.includes(booking.id) ? "green" : ""
                            }`}
                            onClick={async () => {
                              setWaitlistPopup(booking);
                            }}
                          >
                            <FaCheck />
                          </div>
                          <div
                            className={`button small w-fit ml-4 pink`}
                            onClick={async () => {
                              if (
                                // eslint-disable-next-line no-restricted-globals
                                confirm(
                                  "Ben je zeker dat je dit wilt verwijderen?"
                                )
                              ) {
                                await cancelTicket(eventId || "", booking.id);
                                setAttendees(
                                  attendees.filter(a => a !== booking.id)
                                );
                                setBooked(
                                  booked.filter(b => b.id !== booking.id)
                                );
                                setWaitlist(
                                  waitlist.filter(b => b.id !== booking.id)
                                );
                              }
                            }}
                          >
                            <FaTimes />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
      <Dialog
        isOpen={waitlistPopup !== null}
        onRequestClose={() => {
          setWaitlistPopup(null);
        }}
        title={"Wachtlijst beheren"}
        size={"small"}
        padding={true}
      >
        <div>
          Kies hieronder hoe je de persoon in kwestie wilt toelaten op het
          event:
        </div>
        <div className="flex">
          <div
            className="button"
            onClick={async () => {
              if (!waitlistPopup) {
                return;
              }

              await acceptWaitlistWithMail(
                waitlistPopup.id,
                waitlistPopup.email
              );
              setWaitlistPopup(null);
            }}
          >
            Toelaten via mail acceptatie
          </div>
          <div
            className="button pink ml-4"
            onClick={async () => {
              if (!waitlistPopup) {
                return;
              }

              await acceptWaitlist(waitlistPopup.id);
              setWaitlist(waitlist.filter(b => b.id !== waitlistPopup.id));
              setBooked(booked.concat(waitlistPopup));
              setWaitlistPopup(null);
            }}
          >
            Direct toelaten
          </div>
        </div>
      </Dialog>
    </div>
  );
}

import { useState } from "react";
import { useEffectOnce } from "react-use";
import { getCurrentUser, updateCurrentUser, User } from "./api/UserApi";
import { LoadQuestions } from "./LoadQuestions";
import {
  createResponse,
  getCurrentResponses,
  getQuestions,
  Question
} from "./api/QuestionsApi";

export function Profile() {
  const [user, setUser] = useState<User>({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    admin: false
  });
  const [saved, setSaved] = useState(false);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [responses, setResponses] = useState<
    { question: string; response: string }[]
  >([]);

  useEffectOnce(() => {
    getCurrentUser().then(result => {
      setUser(result);
    });

    getQuestions("general").then(quests => {
      setQuestions(quests.reverse());

      getCurrentResponses().then(r => {
        setResponses(
          r.responses
            ? r.responses
            : quests.reverse().map(q => {
                return {
                  question: q.question,
                  response: ""
                };
              })
        );
      });
    });
  });

  return (
    <div className="container">
      <div className="title">Profiel</div>

      <label>Voornaam</label>
      <input
        type="text"
        value={user?.firstName}
        onChange={event => {
          setUser({
            ...user,
            firstName: event.target.value
          });
        }}
      />
      <label>Achternaam</label>
      <input
        type="text"
        value={user?.lastName}
        onChange={event => {
          setUser({
            ...user,
            lastName: event.target.value
          });
        }}
      />
      <label>Email</label>
      <input type="text" disabled={true} value={user?.email} />
      <LoadQuestions
        questions={questions}
        responses={responses}
        setResponses={setResponses}
      />
      <div
        className="button"
        onClick={async () => {
          await updateCurrentUser(user.firstName, user.lastName);
          await createResponse({ responses: responses });
          setSaved(true);
        }}
      >
        Opslaan
      </div>
      {saved ? <div className="green mt-4">Opgeslagen!</div> : null}
    </div>
  );
}

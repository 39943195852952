import { useState } from "react";
import { forgotPassword } from "./api/AuthApi";

export function Forgot() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  return (
    <div className="account container flex flex-column justify-center">
      <div className="title">Wachtwoord vergeten?</div>
      <form
        onSubmit={async e => {
          e.preventDefault();

          await forgotPassword(email);

          setEmail("");
          setMessage("Wachtwoord reset is aangevraagd.");
        }}
      >
        <div className="flex items-center flex-column">
          <input
            className="input"
            type="text"
            placeholder="E-mail"
            value={email}
            onChange={e => {
              setEmail(e.target.value);
            }}
            required
          />
          {message ? <div className="mt-4 mb-4">{message}</div> : null}
          <button type="submit" className="button">
            Reset aanvragen
          </button>
        </div>
      </form>
    </div>
  );
}

import "./Login.css";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CustomResponse, loggedIn, login } from "./api/AuthApi";

export function Login({
  setAuthenticated
}: {
  setAuthenticated: (b: boolean) => void;
}) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [response, setResponse] = useState<CustomResponse | null>(null);
  const navigate = useNavigate();

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const redirectSearch = searchParams.get("redirect");
  const redirectUrl = redirectSearch
    ? decodeURIComponent(redirectSearch)
    : "/user/profile";

  return (
    <div className="account container flex flex-column justify-center">
      <div className="title">Inloggen</div>
      <form
        onSubmit={e => {
          e.preventDefault();
          login(email, password).then(r => {
            setResponse(r);

            if (r.ok) {
              setAuthenticated(true);
            }

            if ((response && response.ok) || loggedIn()) {
              navigate(redirectUrl);
            }
          });
        }}
      >
        <div className="flex items-center flex-column">
          <label>E-mail</label>
          <input
            className="input"
            type="email"
            placeholder="E-mail"
            value={email}
            onChange={e => {
              setEmail(e.target.value);
            }}
            required
          />
          <label>Wachtwoord</label>
          <input
            className="input"
            type="password"
            placeholder="Wachtwoord"
            value={password}
            onChange={e => {
              setPassword(e.target.value);
            }}
            required
          />
          {(response && response.ok) || loggedIn() ? null : (
            <div className="error mb-4">{response?.error}</div>
          )}
          <button type="submit" className="button">
            Inloggen
          </button>
          <div className="mt-4">
            <Link to="/register">Nog geen account?</Link>
          </div>
          <div className="mt-4">
            <Link to="/forgot">Wachtwoord vergeten?</Link>
          </div>
        </div>
      </form>
    </div>
  );
}

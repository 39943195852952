import { API_ENDPOINT, getHeaders } from "./AuthApi";

export type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  admin: boolean;
};

export async function getUser(userId: string): Promise<User> {
  const result = await fetch(`${API_ENDPOINT}/users/${userId}`, {
    method: "GET",
    headers: getHeaders()
  }).then(r => r.json());

  return result.user;
}

export async function getCurrentUser(): Promise<User> {
  const result = await fetch(`${API_ENDPOINT}/user`, {
    method: "GET",
    headers: getHeaders()
  }).then(r => r.json());

  return result.user;
}

export async function deleteUser(userId: string): Promise<User> {
  const result = await fetch(`${API_ENDPOINT}/users/${userId}`, {
    method: "DELETE",
    headers: getHeaders()
  }).then(r => r.json());

  return result.user;
}

export async function updateUser(
  userId: string,
  firstName: string,
  lastName: string,
  email: string
): Promise<User> {
  const result = await fetch(`${API_ENDPOINT}/users/${userId}`, {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify({
      id: userId,
      firstName: firstName,
      lastName: lastName,
      email: email
    })
  }).then(r => r.json());

  return result.user;
}

export async function updateCurrentUser(
  firstName: string,
  lastName: string
): Promise<User> {
  const result = await fetch(`${API_ENDPOINT}/user`, {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify({
      firstName: firstName,
      lastName: lastName
    })
  }).then(r => r.json());

  return result.user;
}

export async function createUser(
  firstName: string,
  lastName: string,
  email: string
): Promise<User> {
  const result = await fetch(`${API_ENDPOINT}/users`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      firstName: firstName,
      lastName: lastName,
      email: email
    })
  }).then(r => r.json());

  return result.user;
}

export async function listUsers(): Promise<User[]> {
  const result = await fetch(`${API_ENDPOINT}/users`, {
    method: "GET",
    headers: getHeaders()
  }).then(r => r.json());

  return result.users;
}

import { useState } from "react";
import { useEffectOnce } from "react-use";
import { useParams } from "react-router-dom";
import { getVisitors, Visitor } from "./api/TicketApi";

export function Visitors() {
  const { eventId } = useParams<{ eventId: string }>();
  const [visitors, setVisitors] = useState<Visitor[]>([]);

  useEffectOnce(() => {
    if (!eventId) {
      return;
    }

    getVisitors(eventId).then(result => {
      setVisitors(result);
    });
  });

  return (
    <div className="container">
      <div className="title">Bezoekers</div>

      {visitors.length === 0 ? (
        "Momenteel nog geen bezoekers beschikbaar"
      ) : (
        <div>
          <table className="table ">
            <thead>
              <tr>
                <th>Naam</th>
                <th>LinkedIn</th>
              </tr>
            </thead>
            <tbody>
              {visitors.map((visitor, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {visitor.firstName} {visitor.lastName}
                    </td>
                    <td>
                      <a
                        href={visitor.linkedIn}
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        LinkedIn
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

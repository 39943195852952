import { useState } from "react";
import { deleteUser, listUsers, User } from "./api/UserApi";
import { useEffectOnce } from "react-use";
import { FaTimes } from "react-icons/fa";
import { AllResponse, getAllResponses } from "./api/QuestionsApi";
import { Checkbox } from "./Checkbox";

export function Users() {
  const [users, setUsers] = useState<User[]>([]);
  const [filter, setFilter] = useState("");
  const [responses, setResponses] = useState<AllResponse[]>([]);
  const [showDetails, setShowDetails] = useState(true);

  useEffectOnce(() => {
    listUsers().then(r => {
      setUsers(r.reverse());
    });

    getAllResponses().then(r => {
      setResponses(r);
    });
  });

  return (
    <div className="container">
      <div className="title">Gebruikers</div>

      <input
        className="input"
        type="text"
        placeholder={"Naam"}
        value={filter}
        onChange={input => {
          setFilter(input.target.value);
        }}
      />
      <Checkbox
        title={"Toon details"}
        value={showDetails}
        onClick={setShowDetails}
      />

      {users.length === 0 ? (
        "Momenteel nog geen gebruikers beschikbaar"
      ) : (
        <table className="table ">
          <thead>
            <tr>
              <th>Naam</th>
              <th>Email</th>
              <th>Vragen</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {users
              .filter(
                u =>
                  `${u.firstName} ${u.lastName}`
                    .toLowerCase()
                    .includes(filter.toLowerCase()) ||
                  u.email.toLowerCase().includes(filter.toLowerCase())
              )
              .map(user => {
                const foundResponse = responses.find(r => r.userId === user.id);
                return (
                  <tr key={user.id}>
                    <td>
                      {user.firstName} {user.lastName}
                    </td>
                    <td>{user.email}</td>
                    <td>
                      {showDetails && foundResponse
                        ? foundResponse.responses.map(q => {
                            return (
                              <>
                                <b>{q.question}</b>
                                <div>{q.response}</div>
                                <div className="mb-4" />
                              </>
                            );
                          })
                        : null}
                    </td>
                    <td>
                      <div className="flex">
                        <div
                          className="button small w-fit pink ml-auto"
                          onClick={async () => {
                            if (
                              // eslint-disable-next-line no-restricted-globals
                              confirm(
                                "Ben je zeker dat je deze gebruiker wilt verwijderen?"
                              )
                            ) {
                              await deleteUser(user.id);
                              setUsers(users.filter(u => u.id !== user.id));
                            }
                          }}
                        >
                          <FaTimes />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
    </div>
  );
}

import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Event, getEvent, remindEvent } from "./api/EventApi";
import { useEffectOnce } from "react-use";
import { Checkbox } from "./Checkbox";
import { ContentEditor } from "./ContentEditor";

export function RemindEventForm() {
  const { eventId } = useParams<{ eventId: string }>();
  const [event, setEvent] = useState<Event | null>(null);
  const [email, setEmail] = useState("");
  const [onlyAttended, setOnlyAttended] = useState(false);
  const [design, setDesign] = useState("null");
  const navigate = useNavigate();

  useEffectOnce(() => {
    getEvent(eventId || "").then(r => {
      setEvent(r);
    });
  });

  if (!event) {
    return null;
  }

  return (
    <div className="container">
      <div className="title">Email versturen</div>
      <div className="flex justify-center">
        <div className="w-100">
          <div>
            <Checkbox
              title={"Email enkel versturen naar aanwezigen"}
              value={onlyAttended}
              onClick={setOnlyAttended}
            />
            <ContentEditor
              design={design}
              save={({ html, design: d }) => {
                setEmail(html);
                setDesign(d);
              }}
              cancel={() => {
                navigate("/admin/events");
              }}
            />
            <div
              className="button"
              onClick={async () => {
                await remindEvent(eventId || "", email, onlyAttended);

                navigate("/admin/events");
              }}
            >
              Versturen
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import "./Home.css";
import { useParams } from "react-router-dom";
import { cancelTicket } from "./api/EventApi";
import { useState } from "react";

export function CancelTicket() {
  const [done, setDone] = useState(false);
  const { eventId, ticketId } = useParams<{
    eventId: string;
    ticketId: string;
  }>();

  return done ? (
    <div className="home container">
      <div className="title">Uitgeschreven</div>
      <div className="subtitle">U heeft u successvol uitgeschreven!</div>
    </div>
  ) : (
    <div className="home container">
      <div className="title">Uitschrijven</div>
      <div className="subtitle">
        Gelieve op de onderstaande knop te klikken om je uit te schrijven.
      </div>
      <div
        className="button ml-auto mr-auto mt-4"
        onClick={async () => {
          await cancelTicket(eventId || "", ticketId || "");
          setDone(true);
        }}
      >
        Uitschrijven
      </div>
    </div>
  );
}
